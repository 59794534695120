import React from "react";
import PropTypes from "prop-types";
import { uid } from "uid";

import {
  BulletsSection,
  Cta,
  DynamicForm,
  ImagesSlider,
  NewText,
  PageList,
  Quote,
  TallyEmbeddedForm,
  Text,
  TextAndSVG,
} from "./sections";
import {
  Outcome,
  Challenges,
  ClientQuote,
  BeforeAfter,
  ResponsiveSlider,
  DiscColor,
  SolutionCarousel,
  MobileSideBySide,
  FullWidthImage,
  NewSolutionCarousel,
} from "./work";

const SlicesWork = ({ allSlices, theme }) => {
  if (!allSlices) return null;

  return allSlices.map(slice => {
    switch (slice.slice_type) {
      case "cta":
        return <Cta key={uid(16)} data={slice} theme={theme} />;
      case "text":
        return <Text key={uid(16)} data={slice} theme={theme} />;
      case "new_text":
        return <NewText key={uid(16)} data={slice} />;
      case "quote":
        return <Quote key={uid(16)} data={slice} theme={theme} />;
      case "pagelist":
        return <PageList key={uid(16)} data={slice} theme={theme} />;
      case "textandsvg":
        return <TextAndSVG key={uid(16)} data={slice} theme={theme} />;
      case "new_text":
        return <NewText key={uid(16)} data={slice} />;
      case "outcome":
        return <Outcome key={uid(16)} data={slice} theme={theme} />;
      case "challenges":
        return <Challenges key={uid(16)} data={slice} theme={theme} />;
      case "clientquote":
        return <ClientQuote key={uid(16)} data={slice} theme={theme} />;
      case "beforeafter":
        return <BeforeAfter key={uid(16)} data={slice} theme={theme} />;
      case "responsiveslider":
        return <ResponsiveSlider key={uid(16)} data={slice} theme={theme} />;
      case "disccolor":
        return <DiscColor key={uid(16)} data={slice} theme={theme} />;
      case "solotioncarousel":
        return <SolutionCarousel key={uid(16)} data={slice} theme={theme} />;
      case "new_solution_carousel":
        return <NewSolutionCarousel key={uid(16)} data={slice} theme={theme} />;
      case "mobilesidebyside":
        return <MobileSideBySide key={uid(16)} data={slice} theme={theme} />;
      case "fullwidthimage":
        return <FullWidthImage key={uid(16)} data={slice} theme={theme} />;
      case "bullets_section":
        return <BulletsSection key={uid(16)} data={slice} />;
      case "images_slider":
        return <ImagesSlider key={uid(16)} data={slice} />;
      case "dynamic_form":
        return <DynamicForm key={uid(16)} data={slice} />;
      case "tally_embedded_form":
        return <TallyEmbeddedForm key={uid(16)} data={slice} />;
      default:
        return null;
    }
  });
};

export default SlicesWork;

SlicesWork.propTypes = {
  allSlices: PropTypes.array,
};
