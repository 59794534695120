import React, { FC, useRef } from "react";
import { PrismicRichText } from "@prismicio/react";
import { linkResolver } from "../../utils/linkResolver";
import { uid } from "uid";

import { Autoplay, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import Section from "../Section";

import color1 from "../../images/plandisc/color-1.png";
import color2 from "../../images/plandisc/color-2.png";
import color3 from "../../images/plandisc/color-3.png";
import { SliceProps } from "../../types";

const colorsDisk1 = [
  "#3B3D64",
  "#54B8B4",
  "#FCE8B7",
  "#FE9E85",
  "#5993C1",
  "#B582BA",
  "#7DBFDB",
  "#C6E283",
];
const colorsDisk2 = [
  "#F1C2D6",
  "#ADE2D1",
  "#78C1EB",
  "#FFBD80",
  "#89A0D9",
  "#E3848A",
  "#87ECFF",
  "#A5C3EF",
];
const colorsDisk3 = [
  "#FF7176",
  "#FFE200",
  "#8491F0",
  "#3281D1",
  "#72E6ED",
  "#58BB58",
  "#BA9373",
  "#F3956B",
];

const DiscColor: FC<SliceProps> = ({ data: { primary } }) => {
  const swiperRef = useRef(null) as any;

  const updateSwiperIndex = index => {
    if (!swiperRef.current) return;

    swiperRef.current.swiper.slideTo(index);
  };

  const { disccolor_title, disccolor_body } = primary;

  return (
    <Section>
      <div className="xl:grid xl:grid-cols-2 xl:gap-32">
        <div>
          <div className="order-1 mb-10">
            <p className="text-pre">SOLUTION</p>
            <div className="max-w-xl">
              {disccolor_title.text && (
                <h2 className="text-h2 mb-6">{disccolor_title.text}</h2>
              )}

              {disccolor_body.richText.length > 0 && (
                <div className="content">
                  <PrismicRichText
                    field={disccolor_body.richText}
                    linkResolver={linkResolver}
                  />
                </div>
              )}
            </div>
          </div>

          <section className="hidden flex-col mb-10 space-y-8 xl:flex">
            <div>
              <p className="mb-3 uppercase text-sm text-gray-600">
                Nordic woods
              </p>

              <DiscColorButton
                index={0}
                colors={colorsDisk1}
                onButtonClick={updateSwiperIndex}
              />
            </div>
            <div>
              <p className="mb-3 uppercase text-sm text-gray-600">Misty rose</p>

              <DiscColorButton
                index={1}
                colors={colorsDisk2}
                onButtonClick={updateSwiperIndex}
              />
            </div>
            <div>
              <p className="mb-3 uppercase text-sm text-gray-600">
                Summer party
              </p>

              <DiscColorButton
                index={2}
                colors={colorsDisk3}
                onButtonClick={updateSwiperIndex}
              />
            </div>
          </section>
        </div>
        <div>
          <Swiper
            modules={[Autoplay, EffectFade]}
            ref={swiperRef}
            className="w-full"
            effect="fade"
          >
            <SwiperSlide>
              <img
                src={color1}
                alt="Color Scheme 1"
                style={{ width: "100%", maxWidth: "606px" }}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={color2}
                alt="Color Scheme 2"
                style={{ width: "100%", maxWidth: "606px" }}
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                src={color3}
                alt="Color Scheme 3"
                style={{ width: "100%", maxWidth: "606px" }}
              />
            </SwiperSlide>
          </Swiper>

          <section className="flex flex-col space-y-8 pt-10 xl:hidden">
            <div>
              <p className="mb-3 uppercase text-sm text-gray-600">
                Nordic woods
              </p>

              <DiscColorButton
                index={0}
                colors={colorsDisk1}
                onButtonClick={updateSwiperIndex}
              />
            </div>
            <div>
              <p className="mb-3 uppercase text-sm text-gray-600">Misty rose</p>

              <DiscColorButton
                index={1}
                colors={colorsDisk2}
                onButtonClick={updateSwiperIndex}
              />
            </div>
            <div>
              <p className="mb-3 uppercase text-sm text-gray-600">
                Summer party
              </p>
              <DiscColorButton
                index={2}
                colors={colorsDisk3}
                onButtonClick={updateSwiperIndex}
              />
            </div>
          </section>
        </div>
      </div>
    </Section>
  );
};

const DiscColorButton = ({ index, colors, onButtonClick }) => {
  if (!colors) return null;

  return (
    // eslint-disable-next-line
    <div className="btn-disc" onClick={() => onButtonClick(index ? index : 0)}>
      <div className="btn-disc__body">
        {colors.map(color => {
          return (
            <span
              key={uid(16)}
              className="btn-disc__item"
              style={{ backgroundColor: color }}
            ></span>
          );
        })}
      </div>
    </div>
  );
};

export default DiscColor;
