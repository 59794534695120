import React from "react";
import { Heading2 } from "../Heading2";
import { uid } from "uid";
import clsx from "clsx";
import { PrismicRichText } from "@prismicio/react";
import { linkResolver } from "../../utils/linkResolver";
import { Paragraph } from "../Paragraph";

enum Layout {
  TWO_COLUMNS = "Two Columns",
  TWO_COLUMNS_REVERSE = "Two Columns Reverse",
  ONE_COLUMN = "One Column",
}

enum TitleSpan {
  NONE = "None",
  HORIZONTALLY = "Horizontally",
  VERTICALLY = "Vertically",
}

export const NewText = ({ data: { primary, items } }) => {
  const {
    new_text_label,
    new_text_title,
    text_title,
    text_layout,
    text_span_title,
    text_centered,
    text_remove_padding_bottom,
    slice_gray_background,
    slice_inverted_colours,
    slice_theme,
  } = primary;

  const twoColumnsLayout =
    text_layout === Layout.TWO_COLUMNS ||
    text_layout === Layout.TWO_COLUMNS_REVERSE;

  const gridClass = `${
    text_centered ? "text-center" : ""
  } grid gap-x-10 gap-y-5 md:gap-y-10 ${
    twoColumnsLayout
      ? "md:grid-flow-col md:grid-rows-[auto_1fr] md:grid-cols-2"
      : "md:grid-rows-1"
  }`;

  let headerClass = "";
  if (text_span_title === TitleSpan.HORIZONTALLY) {
    headerClass = clsx(headerClass, "md:col-span-2");
  } else {
    if (text_span_title === TitleSpan.VERTICALLY) {
      headerClass = clsx(
        headerClass,
        "md:row-span-2 flex flex-col justify-center"
      );
    }
    if (text_layout === Layout.TWO_COLUMNS_REVERSE) {
      headerClass = clsx(headerClass, "md:col-start-2");
    }
  }

  let item1Class =
    text_layout === Layout.TWO_COLUMNS_REVERSE
      ? "md:row-start-2 md:col-start-2"
      : "";

  return (
    <div
      className={clsx(
        "px-5 sm:px-10",
        text_remove_padding_bottom ? "pt-10 md:pt-20" : "py-10 md:py-20",
        slice_gray_background && "bg-gray-100",
        slice_inverted_colours && "bg-primary text-white",
        slice_theme
      )}
    >
      <div className={clsx(gridClass, "max-w-7xl mx-auto")}>
        <div className={headerClass}>
          {new_text_label && <p className="text-pre">{new_text_label}</p>}

          {new_text_title && (
            <div>
              <Heading2 className={`${text_centered ? "" : "ml-0"}`}>
                {new_text_title.text}
              </Heading2>
            </div>
          )}

          {!new_text_title && text_title && (
            <div>
              <Heading2 className={`${text_centered ? "" : "ml-0"}`}>
                {text_title.text}
              </Heading2>
            </div>
          )}
        </div>
        {items && (
          <>
            {items.map(({ column_body }, index: number) => (
              <div
                key={uid(16)}
                className={clsx(
                  "content",
                  `${
                    twoColumnsLayout &&
                    text_span_title !== TitleSpan.HORIZONTALLY &&
                    index == 1
                      ? "md:row-start-1 md:row-end-3"
                      : ""
                  }`,
                  `${index == 0 ? item1Class : ""}`
                )}
              >
                <PrismicRichText
                  field={column_body.richText}
                  linkResolver={linkResolver}
                  components={{
                    paragraph: ({ children }) => (
                      <Paragraph className="md:!mt-0">{children}</Paragraph>
                    ),
                    image: ({ node }) => {
                      const isSVG = node.url.split(".").pop() === "svg";
                      const imageWidth = isSVG
                        ? node.dimensions.width
                        : node.dimensions.width / 2;

                      return (
                        <p className="block-img flex justify-center items-center h-full">
                          <img
                            width={imageWidth}
                            src={node.url}
                            alt={node.alt || ""}
                          />
                        </p>
                      );
                    },
                  }}
                />
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};
