import React from "react"
import { Link } from "gatsby"
import Icon from "../icon"

import Address from "../components/Address"
import clsx from "clsx"

const Footer = ({ theme }) => {
  return (
    <footer
      className={clsx(
        "py-10 lg:py-16 || text-base text-white bg-tertiary",
        theme,
        "t-page"
      )}
    >
      <div className="px-5 m-auto sm:px-10 lg:px-16 max-w-screen-xl3 lg:flex xl:justify-between">
        <section className="lg:order-1 mb-12 lg:mb-0">
          <div className="grid grid-cols-1 gap-y-8 lg:grid-cols-2 lg:gap-y-10 lg:gap-x-40 lg:max-w-3xl xl:grid-cols-3 xl:gap-x-24 xl2:max-w-4xl xl2:gap-x-32  leading-snug">
            <Address />
          </div>
        </section>
        <section className="lg:mr-40 xl:mr-0">
          <div className="">
            <div className="xl:flex xl:space-x-24 xl3:space-x-28">
              <Link to="/" className="inline-block mb-6">
                <Icon
                  name="brand"
                  theme={theme}
                  className="brand brand--footer"
                />
              </Link>
              <p className="font-display lowercase mb-2 text-secondary">
                Digital Product Studio
              </p>
            </div>
            <div>
              <ul className="flex space-x-2">
                <li>
                  <a
                    href="https://www.facebook.com/thisisstokedcom"
                    aria-label="Facebook"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon
                      name="facebook"
                      className="text-secondary hover:text-secondary-hover"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/thisisstoked/"
                    aria-label="Instagram"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon
                      name="instagram"
                      className="text-secondary hover:text-secondary-hover"
                    />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/thisisstoked/"
                    aria-label="Linkedin"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Icon
                      name="linkedin"
                      className="text-secondary hover:text-secondary-hover"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </section>
      </div>
    </footer>
  )
}

export default Footer
