import React from "react";

const getThemeColorAndTone = themeString => {
  const themeColorPattern = /^(.*?)(?=-\d{3}$)/;
  const colorTonePattern = /\d{3}$/;

  const themeColorMatch = themeString.match(themeColorPattern);
  const colorToneMatch = themeString.match(colorTonePattern);

  return [themeColorMatch[0], colorToneMatch[0]];
};

const colorConfig = {
  "theme-purple": {
    default: {
      fill: "#D19BCC",
      fillAccent: "#E8CDE6",
    },
    "200": {
      fill: "#BF8ABA",
      fillAccent: "#E8CDE6",
    },
  },
  "theme-blue": {
    default: {
      fill: "#4FCCFA",
      fillAccent: "#A7E6FD",
    },
    "200": {
      fill: "#43BCE8",
      fillAccent: "#A7E6FD",
    },
  },
  "theme-red": {
    default: {
      fill: "#F08484",
      fillAccent: "#F8C2C2",
    },
    "200": {
      fill: "#D1706F",
      fillAccent: "#F8C2C2",
    },
  },
};

const SVG = ({
  theme = "theme-purple-400",
  width = "10.75rem",
  height = "2.5rem",
  className = "brand",
  viewBox = "0 0 2158.6 502.34",
}) => {
  const [themeColor, colorTone] = getThemeColorAndTone(theme);

  let fill =
    colorConfig[themeColor][colorTone]?.fill ||
    colorConfig[themeColor].default.fill;
  let fillAccent =
    colorConfig[themeColor][colorTone]?.fillAccent ||
    colorConfig[themeColor].default.fillAccent;

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      className={`svg-icon ${className || ""}`}
      clipRule="evenodd"
      fillRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g fillRule="nonzero">
        <g fill="#fff">
          <path d="m1461.56 310.67c11.45-6.98 23.41-13.14 35.71-18.35 29.28-12.4 60.86-19.64 92.73-19.7h92.91v-3.86c0-24.87-10.2-47.51-26.63-63.95-16.43-16.43-39.07-26.64-63.95-26.64h-40.18c-24.88 0-47.52 10.21-63.95 26.64s-26.63 39.07-26.63 63.95v41.91zm128.43 28.02c-23.07.04-45.99 5.3-67.18 14.28-18.24 7.73-35.55 18.31-51.09 31.08 4.32 8.3 9.89 15.87 16.47 22.44 16.43 16.43 39.06 26.64 63.94 26.64h133.7c4.15 0 7.54 3.39 7.54 7.54v50.99c0 4.15-3.4 7.54-7.54 7.54-44.57 0-89.14 0-133.7 0-43.11 0-82.28-17.61-110.66-45.99s-45.99-67.55-45.99-110.66v-73.78c0-43.11 17.62-82.29 45.99-110.66 28.38-28.38 67.55-45.99 110.66-45.99h40.18c43.12 0 82.29 17.61 110.66 45.99 28.38 28.38 45.99 67.55 45.99 110.66v62.38c0 4.15-3.4 7.54-7.54 7.54h-151.45z" />
          <path d="m1188.92 350.5-112.42 142.94c-3.07 3.9-7.35 5.98-12.32 5.97l-33.88-.03c-4.15 0-7.54-3.4-7.54-7.54 0-159.38 0-324.88 0-484.26 0-4.15 3.39-7.54 7.54-7.54h50.99c4.15 0 7.54 3.4 7.54 7.54v363.44l203.73-258.99c2.56-3.26 7.33-3.83 10.59-1.27l40.08 31.52c3.26 2.57 3.83 7.33 1.27 10.59l-79.72 101.34c-11.65 14.81-17.53 32.21-17.53 49.64s5.89 34.83 17.54 49.64l82.63 105.11c2.57 3.27 2 8.03-1.26 10.59l-40.07 31.52c-3.26 2.57-8.03 2-10.59-1.27l-82.64-105.11c-10.67-13.56-18.65-28.4-23.92-43.86z" />
          <path d="m2092.52 342.75v-73.78c0-24.87-10.2-47.51-26.63-63.94s-39.07-26.64-63.95-26.64h-40.19c-24.87 0-47.52 10.2-63.94 26.64-16.43 16.43-26.64 39.07-26.64 63.94v73.78c0 24.87 10.2 47.51 26.64 63.95 16.43 16.43 39.07 26.63 63.94 26.63h40.19c24.87 0 47.51-10.2 63.95-26.63 16.43-16.43 26.63-39.07 26.63-63.95zm16.9 113.75c-28.09 26.56-65.96 42.9-107.48 42.9h-40.19c-43.11 0-82.29-17.62-110.66-45.99-28.38-28.38-45.99-67.55-45.99-110.66v-73.78c0-43.11 17.61-82.29 45.99-110.66 28.37-28.38 67.55-45.99 110.66-45.99h40.19c33.69 0 64.97 10.76 90.58 29 0-44.57 0-89.15 0-133.73 0-4.15 3.4-7.54 7.54-7.54h50.99c4.15 0 7.54 3.4 7.54 7.54v441.38c0 4.15-3.39 7.54-7.54 7.54-13.88 0-27.76 0-41.63 0z" />
          <path d="m374.15 392.37v-214.03h-49.45c-4.15 0-7.54-3.39-7.54-7.54v-50.99c0-4.15 3.4-7.54 7.54-7.54h49.45c0-34.91 0-69.82 0-104.73 0-4.15 3.39-7.54 7.54-7.54h50.99c4.15 0 7.54 3.4 7.54 7.54v104.73h78.68c4.15 0 7.54 3.39 7.54 7.54v50.99c0 4.15-3.39 7.54-7.54 7.54h-78.68v214.03c0 11.22 4.61 21.44 12.04 28.87s17.65 12.04 28.87 12.04c8.5 0 16.99-.01 25.49-.01 4.15 0 7.54 3.39 7.54 7.54v50.99c0 4.15-3.39 7.54-7.54 7.54-8.49 0-16.99 0-25.49 0-29.45 0-56.21-12.02-75.58-31.4-19.38-19.37-31.4-46.13-31.4-75.59z" />
          <path d="m104.75 178.36c-21.75-.06-38.58 17.02-38.93 38.48.24 14.9 8.41 28.34 21.9 34.84l101.66 49.03c15.16 7.31 27.76 17.82 37.38 30.33l-52.04 40.02c-3.44-4.35-8.06-8.13-13.97-10.98l-101.66-49.03c-36.48-17.59-58.82-54.1-59.07-94.45.57-57.02 45.96-104.48 104.75-104.32 43.96 0 85.51-.02 129.47-.02 4.15 0 7.54 3.4 7.54 7.54v50.99c0 4.15-3.4 7.54-7.54 7.54-43.96 0-85.51.02-129.47.02z" />
        </g>
        <path
          d="m226.75 331.05c6.86 8.92 12.2 18.85 15.88 29.38 16.12 46.14-1.91 97.25-43.22 123.09-16.54 10.35-35.89 15.9-55.71 15.84v-66.07c34 .1 49.32-39.07 31-62.21l11.91-9.16-11.91 9.16c-2.53-3.29-1.91-8.05 1.38-10.58l40.09-30.81c3.29-2.53 8.04-1.91 10.57 1.38z"
          fill={fillAccent}
        />
      </g>
      <path
        d="m143.7 433.27h-50.99c-4.15 0-7.54 3.4-7.54 7.54v50.99c0 4.15 3.4 7.54 7.54 7.54h50.99c4.15 0 7.54-3.39 7.54-7.54v-50.99c0-4.15-3.39-7.54-7.54-7.54z"
        fill={fill}
      />
      <path
        d="m782.77 499.36c43.11 0 82.28-17.62 110.66-45.99 28.38-28.38 45.99-67.55 45.99-110.66v-73.78c0-43.11-17.61-82.29-45.99-110.66-28.38-28.38-67.55-45.99-110.66-45.99h-40.19c-27.53 0-53.46 7.19-75.99 19.77l32.19 57.71c13.01-7.25 27.95-11.4 43.8-11.4h40.19c24.87 0 47.52 10.2 63.95 26.63s26.63 39.07 26.63 63.95v73.78c0 24.87-10.2 47.51-26.63 63.95-16.43 16.43-39.07 26.63-63.95 26.63h-40.19c-4.15 0-7.54 3.4-7.54 7.54v50.99c0 4.15 3.4 7.54 7.54 7.54h40.19z"
        fill="#fff"
        fillRule="nonzero"
      />
      <path
        d="m698.78 189.74c-7.41 4.13-14.19 9.27-20.15 15.24-16.43 16.43-26.63 39.07-26.63 63.95v11.25h-66.07v-11.25c0-43.12 17.61-82.29 45.99-110.66 10.25-10.26 21.92-19.11 34.67-26.23 3.62-2.02 8.24-.71 10.26 2.91l24.84 44.54c2.02 3.62.71 8.24-2.91 10.26z"
        fill={fillAccent}
        fillRule="nonzero"
      />
      <path
        d="m644.46 272.62h-50.99c-4.15 0-7.54 3.4-7.54 7.54v50.99c0 4.15 3.39 7.54 7.54 7.54h50.99c4.15 0 7.54-3.39 7.54-7.54v-50.99c0-4.15-3.4-7.54-7.54-7.54z"
        fill={fill}
      />
    </svg>
  );
};

export default SVG;
