import React from "react";
import { Link } from "gatsby";
import { linkResolver } from "../../utils/linkResolver";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { uid } from "uid";
import Section from "../Section";
import GatsbyImage from "../GatsbyImage";
import clsx from "clsx";

const SwiperList = ({ data: { primary, items } }) => {
  const { page_list_title, slice_theme } = primary;

  return (
    <Section paddingY={clsx("py-16 sm:py-20 lg:py-24", slice_theme)}>
      {page_list_title.text && (
        <div className="text-center mb-8 sm:mb-16">
          <h2 className="text-h2">{page_list_title.text}</h2>
        </div>
      )}

      {items && (
        <Swiper
          modules={[Pagination]}
          className="page-list relative pb-20"
          breakpoints={{
            1280: {
              spaceBetween: 60,
              slidesPerView: 3,
            },
            786: {
              spaceBetween: 60,
              slidesPerView: 2,
            },
            320: {
              spaceBetween: 20,
              centeredSlides: true,
              slidesPerView: 1,
            },
          }}
          loop
          slidesPerView="auto"
          grabCursor
          pagination={{
            clickable: true,
            renderBullet: (index, className) => {
              return `<span class="${className}"></span>`;
            },
          }}
        >
          {items.map(
            ({
              page_list_item_title,
              page_list_item_label,
              page_list_item_link,
            }) => {
              if (!page_list_item_title && !page_list_item_link) return null;

              const { title } = page_list_item_link;
              const { image } = page_list_item_link.document.data;
              const titleText = page_list_item_title || title;

              return (
                <SwiperSlide key={uid(16)}>
                  <Link
                    className="group"
                    to={linkResolver(page_list_item_link)}
                  >
                    {image && (
                      <GatsbyImage
                        className="relative pb-1/1 bg-gray-200 rounded-md overflow-hidden h-0"
                        imgClassName="!transition !ease-in-out !duration-300 group-hover:scale-105"
                        image={image}
                      />
                    )}
                    <div className="mt-2">
                      {titleText.text && (
                        <h3 className="font-display text-lg tracking-[.01em]">
                          {titleText.text}
                        </h3>
                      )}
                      {page_list_item_label && (
                        <p className="text-sm">
                          {page_list_item_label.text
                            ? page_list_item_label.text
                            : page_list_item_label}
                        </p>
                      )}
                    </div>
                  </Link>
                </SwiperSlide>
              );
            }
          )}
        </Swiper>
      )}
    </Section>
  );
};

export default SwiperList;
