import React, { FC } from "react";
import { PrismicRichText } from "@prismicio/react";
import { linkResolver } from "../../utils/linkResolver";
import { Heading2 } from "../Heading2";

import Section from "../Section";
import Button from "../Button";
import { Paragraph } from "../Paragraph";
import clsx from "clsx";

type CTAProps = {
  data: any;
  theme?: string;
};

const CTA: FC<CTAProps> = ({ data: { primary }, theme }) => {
  const { cta_title, cta_body, cta_text, cta_link, slice_theme } = primary;

  return (
    <Section
      className={clsx(
        "bg-primary text-body xs:text-center text-white",
        slice_theme
      )}
      paddingY="py-8 sm:py-20 lg:py-24"
    >
      <div className="max-w-4xl m-auto">
        {cta_title.text && (
          <Heading2 className="xs:text-center">{cta_title.text}</Heading2>
        )}

        {cta_body?.richText.length > 0 && (
          <PrismicRichText
            field={cta_body.richText}
            linkResolver={linkResolver}
            components={{
              paragraph: ({ children }) => (
                <Paragraph className="mb-8">{children}</Paragraph>
              ),
            }}
          />
        )}

        {cta_link && cta_text.text && (
          <div className="flex justify-center mt-12">
            <Button linkMeta={cta_link}>{cta_text.text}</Button>
          </div>
        )}
      </div>
    </Section>
  );
};

export default CTA;
