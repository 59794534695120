import React from "react";
import { Heading2 } from "./Heading2";

const SectionText = ({ label, headline, text }) => {
  return (
    <>
      {label && (
        <div>
          <p className="text-pre">{label}</p>
        </div>
      )}
      <div className="grid gap-6 max-w-xl lg:grid-cols-2 lg:max-w-full lg:gap-16">
        {headline && (
          <div>
            <Heading2 className="text-h2 xl:max-w-lg ml-0">{headline}</Heading2>
          </div>
        )}
        {text && <div className="content">{text}</div>}
      </div>
    </>
  );
};

export default SectionText;
