import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { linkResolver } from "../../utils/linkResolver";
import { uid } from "uid";
import SwiperCore, { Controller, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import GatsbyImage from "../GatsbyImage";
import { Heading2 } from "../Heading2";
import clsx from "clsx";

SwiperCore.use([Controller, Pagination]);

export const NewSolutionCarousel = ({ data: { primary, items }, theme }) => {
  const {
    solution_carousel_title,
    solution_carousel_body,
    slice_gray_background,
    slice_inverted_colours,
    slice_theme,
  } = primary;

  return (
    <section
      className={clsx(
        slice_gray_background && "bg-gray-100",
        slice_inverted_colours && "bg-primary text-white",
        slice_theme
      )}
    >
      <div className="overflow-hidden py-10 md:py-32">
        <div className="px-5 sm:px-10 grid max-w-7xl m-auto sm:grid-cols-2 gap-8 md:gap-16">
          {solution_carousel_title.text && (
            <div>
              <div>
                <p className="text-pre">SOLUTION</p>
              </div>
              <div>
                <Heading2>{solution_carousel_title.text}</Heading2>
              </div>
            </div>
          )}
          {solution_carousel_body.richText && (
            <div>
              <PrismicRichText
                field={solution_carousel_body.richText}
                linkResolver={linkResolver}
              />
            </div>
          )}
        </div>

        <section
          className={clsx(
            "solution-carousel pb-8 md:pb-12 mt-8",
            slice_inverted_colours ? "inverted" : ""
          )}
        >
          {items && (
            <Swiper
              breakpoints={{
                1280: {
                  spaceBetween: 30,
                  slidesOffsetBefore: 64,
                  slidesOffsetAfter: 64,
                },
                786: {
                  spaceBetween: 30,
                  slidesOffsetBefore: 32,
                  slidesOffsetAfter: 32,
                },
                768: {
                  spaceBetween: 30,
                  slidesOffsetBefore: 32,
                  slidesOffsetAfter: 32,
                  centeredSlides: false,
                },
                320: {
                  spaceBetween: 0,
                  centeredSlides: true,
                },
              }}
              loop
              slidesPerView="auto"
              grabCursor
              pagination={{
                clickable: true,
                renderBullet: (index, className) => {
                  return `<span class="${className}"></span>`;
                },
              }}
            >
              {items
                .filter((i: any) => !!i.solution_carousel_item_image)
                .map(
                  ({
                    solution_carousel_item_image,
                    solution_carousel_item_description,
                  }) => (
                    <SwiperSlide
                      key={uid(16)}
                      className="w-[80%] md:w-[42%] lg:w-[36.39%]"
                    >
                      <div>
                        <GatsbyImage
                          image={solution_carousel_item_image}
                          className="h-full w-full object-cover bg-white rounded-md"
                        />
                        <div className="mt-6">
                          <PrismicRichText
                            field={solution_carousel_item_description.richText}
                            linkResolver={linkResolver}
                          />
                        </div>
                      </div>
                    </SwiperSlide>
                  )
                )}
            </Swiper>
          )}
        </section>
      </div>
    </section>
  );
};
