import React, { useEffect, useRef } from "react";
import clsx from "clsx";
import { usePageScrollDown } from "./usePageScrollDown";

interface UseTrailOptions {
  transitionDelaySec?: number;
  maxTranslationPx?: number;
  translationFactor?: number;
  scaleFactor?: number;
}

export const useTrail = ({
  transitionDelaySec = 0.2,
  maxTranslationPx = 50,
  translationFactor = 20,
  scaleFactor = 1.3,
}: UseTrailOptions = {}) => {
  const wrapperRef = useRef<HTMLElement>(null);
  const translationRef = useRef(0);

  const onScrollDown = (deltaScroll: number) => {
    let newTargetTranslation = Math.abs(deltaScroll) * translationFactor;

    newTargetTranslation = Math.min(newTargetTranslation, maxTranslationPx);
    updateTranslation(newTargetTranslation);
  };

  const onScrollStop = () => {
    updateTranslation(0);
  };

  const [pauseScroll, restartScroll] = usePageScrollDown(
    onScrollDown,
    onScrollStop
  );

  const checkScreenSize = () => {
    if (window.matchMedia("(max-width: 767px)").matches) {
      pauseScroll();
      updateTranslation(0);
    } else {
      restartScroll();
    }
  };

  useEffect(() => {
    if (!wrapperRef.current) return;

    wrapperRef.current.style.setProperty(
      "--before-delay",
      `${transitionDelaySec}s`
    );
    wrapperRef.current.style.setProperty("--before-translation", "0px");
    wrapperRef.current.style.setProperty("--after-delay", "0s");
    wrapperRef.current.style.setProperty("--after-translation", "0px");

    window.addEventListener("resize", checkScreenSize);

    checkScreenSize();
  }, []);

  const updateTranslation = (value: number) => {
    if (!wrapperRef.current) return;

    let expanding = value >= translationRef.current;

    let beforeTransitionDelay = expanding ? transitionDelaySec : 0;
    let afterTransitionDelay = expanding ? 0 : transitionDelaySec;

    wrapperRef.current!.style.setProperty(
      "--before-delay",
      `${beforeTransitionDelay}s`
    );
    wrapperRef.current!.style.setProperty("--before-translation", `${value}px`);
    wrapperRef.current!.style.setProperty(
      "--after-delay",
      `${afterTransitionDelay}s`
    );
    wrapperRef.current!.style.setProperty(
      "--after-translation",
      `${value * scaleFactor}px`
    );

    translationRef.current = value;
  };

  const WrapperEl = ({ Tag, children, className, theme }) => (
    <Tag
      ref={wrapperRef}
      className={clsx(
        "item-animation relative z-[0] [--before-translation: 0] [--before-delay: .01s] [--after-translation: 0] [--after-delay: 0]",
        `before:absolute before:top-0 before:w-full before:h-full before:transition-transform before:duration-[.6s] before:ease-in-out before:-z-[1] before:bg-secondary before:opacity-80 before:delay-[var(--before-delay)] before:translate-y-[var(--before-translation)]`,
        `after:absolute after:top-0 after:w-full after:h-full after:transition-transform after:duration-[.6s] after:ease-in-out after:-z-[2] after:bg-secondary after:opacity-50 after:delay-[var(--after-delay)] after:translate-y-[var(--after-translation)]`,
        theme,
        className
      )}
    >
      {children}
    </Tag>
  );

  return WrapperEl;
};
