import React, { FC, PropsWithChildren } from "react";
import clsx from "clsx";

type Heading2Props = {
  className?: string;
};

export const Heading2: FC<PropsWithChildren<Heading2Props>> = ({
  children,
  className,
}) => (
  <h2
    className={clsx(
      "max-w-3xl mx-auto text-2xl sm:text-3xl md:text-4.5xl font-display",
      className
    )}
  >
    {children}
  </h2>
);
