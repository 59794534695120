import React from "react";

const HamburgerClosed = () => {
  return (
    <svg
      width="18"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0.526379V4.08582C0 4.37659 1.22651 4.6123 2.73948 4.6123H21.2605C22.7735 4.6123 24 4.37659 24 4.08582V0.526379C24 0.235609 22.7735 -0.000106812 21.2605 -0.000106812H2.73948C1.22651 -0.000106812 0 0.235609 0 0.526379Z"
        fill="#FEFEFE"
      />
      <path
        d="M0 10.2066V13.766C0 14.0568 1.22651 14.2925 2.73948 14.2925H21.2605C22.7735 14.2925 24 14.0568 24 13.766V10.2066C24 9.91578 22.7735 9.68007 21.2605 9.68007H2.73948C1.22651 9.68007 0 9.91578 0 10.2066Z"
        fill="#FEFEFE"
      />
      <path
        d="M0 19.9155V23.475C0 23.7657 1.22651 24.0015 2.73948 24.0015H21.2605C22.7735 24.0015 24 23.7657 24 23.475V19.9155C24 19.6248 22.7735 19.3891 21.2605 19.3891H2.73948C1.22651 19.3891 0 19.6248 0 19.9155Z"
        fill="#FEFEFE"
      />
      <path
        d="M11.4038 19.9155V23.475C11.4038 23.7657 12.0472 24.0015 12.8409 24.0015H22.5566C23.3502 24.0015 23.9936 23.7657 23.9936 23.475V19.9155C23.9936 19.6248 23.3502 19.3891 22.5566 19.3891H12.8409C12.0472 19.3891 11.4038 19.6248 11.4038 19.9155Z"
        className="text-primary"
        fill="currentColor"
      />
      <path
        d="M20.4561 19.9155V23.475C20.4561 23.7657 20.6372 24.0015 20.8606 24.0015H23.5956C23.8191 24.0015 24.0002 23.7657 24.0002 23.475V19.9155C24.0002 19.6248 23.8191 19.3891 23.5956 19.3891H20.8606C20.6372 19.3891 20.4561 19.6248 20.4561 19.9155Z"
        className="text-secondary-hover"
        fill="currentColor"
      />
    </svg>
  );
};

export default HamburgerClosed;
