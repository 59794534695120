import React, { FC } from "react";
import clsx from "clsx";
import GatsbyImage from "../GatsbyImage";

type WorkHeroProps = {
  bgImage: any;
  featuredImage?: any;
  className?: string;
  hasImageOverlay?: boolean;
};

export const WorkHero: FC<WorkHeroProps> = ({
  bgImage,
  featuredImage,
  className,
  hasImageOverlay = false,
}) => {
  const bgImageStyle = {
    backgroundColor: "#EDF2F7", // bg-gray-200
    backgroundImage: `url(${bgImage})`,
  };

  const bgImageStyleOverlay = {
    backgroundColor: "#EDF2F7", // bg-gray-200
    backgroundImage: `linear-gradient(
      to bottom,
      hsla(0, 0%, 0%, 0.6) 0%,
      hsla(0, 0%, 0%, 0.592) 0.3%,
      hsla(0, 0%, 0%, 0.571) 1%,
      hsla(0, 0%, 0%, 0.538) 2.1%,
      hsla(0, 0%, 0%, 0.495) 3.7%,
      hsla(0, 0%, 0%, 0.444) 5.5%,
      hsla(0, 0%, 0%, 0.389) 7.7%,
      hsla(0, 0%, 0%, 0.33) 10.1%,
      hsla(0, 0%, 0%, 0.27) 12.8%,
      hsla(0, 0%, 0%, 0.211) 15.6%,
      hsla(0, 0%, 0%, 0.156) 18.5%,
      hsla(0, 0%, 0%, 0.105) 21.5%,
      hsla(0, 0%, 0%, 0.062) 24.5%,
      hsla(0, 0%, 0%, 0.029) 27.5%,
      hsla(0, 0%, 0%, 0.008) 30.5%,
      hsla(0, 0%, 0%, 0) 33.3%,
      hsla(0, 0%, 0%, 0) 100%
    ), url(${bgImage})`,
  };

  const bgImageStyleType = hasImageOverlay ? bgImageStyleOverlay : bgImageStyle;
  const headerStyle = bgImage ? bgImageStyleType : {};

  return (
    <div
      className={clsx(
        "text-body bg-primary h-[calc(100vw/2.8)] max-h-[1100px] bg-cover bg-no-repeat bg-center",
        className
      )}
      style={headerStyle}
    >
      {featuredImage?.gatsbyImageData && (
        <div className="max-w-8xl m-auto items-end h-full px-5 sm:px-10 lg:px-16 hidden md:flex">
          <GatsbyImage
            image={featuredImage}
            className="max-w-xs lg:max-w-md xl:max-w-2xl"
          />
        </div>
      )}
    </div>
  );
};
