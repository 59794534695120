import React, { useState } from "react";
import { PrismicRichText } from "@prismicio/react";
import { linkResolver } from "../../utils/linkResolver";
import { uid } from "uid";

import SwiperCore, { Controller, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import GatsbyImage from "../GatsbyImage";
import clsx from "clsx";
import { Heading2 } from "../Heading2";

SwiperCore.use([Controller, Pagination]);

const SolutionCarousel = ({ data: { primary, items }, theme }) => {
  const [textIndex, setTextIndex] = useState(0);

  const { solotioncarousel_title, solotioncarousel_theme } = primary;

  const themeClass = solotioncarousel_theme || theme;

  return (
    <section
      className={`${
        themeClass ? themeClass : "theme-purple-400"
      } bg-primary text-white inverted`}
    >
      <div className="overflow-hidden py-16 md:py-32">
        <div className="flex flex-col max-w-7xl m-auto xl:grid xl:grid-cols-2">
          {solotioncarousel_title.text && (
            <section className="order-1 xl:order-2 pb-10 md:pb-12 xl:col-span-1 xl:-mt-6">
              <div className="gallery-text">
                <div>
                  <p className="text-pre">SOLUTION</p>
                </div>

                <div className="text-left">
                  <div>
                    <Heading2>{solotioncarousel_title.text}</Heading2>
                  </div>
                </div>
              </div>
            </section>
          )}

          {items && (
            <section className="order-3 xl:order-3 xl:col-span-1 xl:-mt-6">
              <div className="gallery-text xl:pt-8">
                <div className="content">
                  <PrismicRichText
                    field={items[textIndex].solotioncarousel_body.richText}
                    linkResolver={linkResolver}
                  />
                </div>
              </div>
            </section>
          )}

          <section className="order-2 pb-8 md:pb-12 xl:order-1 xl:col-span-2">
            {items && (
              <Swiper
                className="swiper-container swiper-container--gallery"
                breakpoints={{
                  1280: {
                    spaceBetween: 30,
                    centeredSlides: false,
                    initialSlide: 1,
                  },
                  786: {
                    spaceBetween: 30,
                    centeredSlides: true,
                  },
                  320: {
                    spaceBetween: 0,
                    centeredSlides: true,
                  },
                }}
                loop
                slidesPerView="auto"
                grabCursor
                pagination={{
                  clickable: true,
                  renderBullet: (index, className) => {
                    return `<span class="${className}"></span>`;
                  },
                }}
                onSlideChange={event => {
                  setTextIndex(event.realIndex);
                }}
              >
                {items
                  .filter(i => !!i.solotioncarousel_item_image)
                  .map(({ solotioncarousel_item_image }) => (
                    <SwiperSlide key={uid(16)} className="gallery-slide">
                      {({ isDuplicate }) => (
                        <div
                          className={clsx(
                            "gallery-slide__inner",
                            isDuplicate && "is-duplicate"
                          )}
                        >
                          <GatsbyImage
                            image={solotioncarousel_item_image}
                            className="h-full w-full object-cover bg-gray-200 rounded-md"
                          />
                        </div>
                      )}
                    </SwiperSlide>
                  ))}
              </Swiper>
            )}
          </section>
        </div>
      </div>
    </section>
  );
};

export default SolutionCarousel;
