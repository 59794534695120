import React, { FC, PropsWithChildren } from "react";

type ParagraphProps = {
  className?: string;
};

export const Paragraph: FC<PropsWithChildren<ParagraphProps>> = ({
  children,
  className = "",
}) => (
  <p
    className={`content text-sm sm:text-base md:text-lg mt-2 sm:mt-5 md:mt-8 leading-5.5 sm:leading-6 md:leading-7 ${className}`}
  >
    {children}
  </p>
);
