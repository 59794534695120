import React from "react";
import Section from "../Section";
import { graphql, useStaticQuery } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import { uid } from "uid";
import { TeamCard } from "../TeamCard";
import { Heading2 } from "../Heading2";
import { Paragraph } from "../Paragraph";
import { linkResolver } from "../../utils/linkResolver";
import clsx from "clsx";

const Team = ({ data: { primary } }) => {
  const { section_title, section_body, slice_theme } = primary;

  const teamMembersData = useStaticQuery(teamMembersQuery);
  const items = teamMembersData.allPrismicTeamMember?.edges;

  return (
    <Section
      paddingY={clsx(
        "pt-0 pb-16 sm:pb-20 lg:pb-16 mt-10 md:mt-20",
        slice_theme
      )}
    >
      <div className="max-w-3xl mx-auto">
        <Heading2 className="text-center">{section_title.text}</Heading2>
        <PrismicRichText
          field={section_body.richText}
          components={{
            paragraph: ({ children }) => (
              <Paragraph className="sm:text-center">{children}</Paragraph>
            ),
          }}
          linkResolver={linkResolver}
        />
      </div>
      {items && (
        <div className="xs:mx-auto xs:w-fit md:w-full sm:mx-0 grid gap-8 grid-cols-1 md:place-items-center md:place-content-center sm:grid-cols-2 sm:gap-12 lg:grid-cols-3 pt-20">
          {items
            .filter(
              ({
                node: {
                  data: { image },
                },
              }) => !!image?.gatsbyImageData
            )
            .map(({ node: { data } }) => {
              return <TeamCard key={uid(16)} {...data} />;
            })}
        </div>
      )}
    </Section>
  );
};

const teamMembersQuery = graphql`
  query TeamMembers {
    allPrismicTeamMember(sort: { first_publication_date: ASC }) {
      edges {
        node {
          data {
            image {
              gatsbyImageData
            }
            skills {
              text
            }
            name {
              text
            }
          }
        }
      }
    }
  }
`;

export default Team;
