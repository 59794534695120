module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WJBM5NH","includeInDevelopment":true,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"stoked","apiEndpoint":"https://stoked.prismic.io/api/v2","accessToken":"MC5YdW5aWnhRQUFDa0FaVThP.N--_ve-_ve-_vSvvv71f77-977-9fBZoKl7vv73vv70HHu-_vV4c77-977-9BB5X77-9TO-_ve-_vTfvv70","customTypesApiToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoibWFjaGluZTJtYWNoaW5lIiwiZGJpZCI6InN0b2tlZC00MGQ4MGM0OS0xMjYzLTQ0ZDEtYjIyYi1kYzAzZmJkODkyNjRfMyIsImRhdGUiOjE2NDI3NjQxODUsImRvbWFpbiI6InN0b2tlZCIsImlhdCI6MTY0Mjc2NDE4NX0.u8aS-Ftuk6R-GQbp6bnzsqEcqEeydDu00NuYB3HZ3bA","lang":"en-us","promptForAccessToken":true,"pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
