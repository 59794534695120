import React from "react";
import ReactCompareImage from "react-compare-image";

const BeforeAfter = ({ primary }) => {
  const { beforeafter_image_before, beforeafter_image_after } = primary;

  return (
    <section className="ba">
      <div className="ba__border">
        <ReactCompareImage
          leftImage={beforeafter_image_before.url}
          rightImage={beforeafter_image_after.url}
        />
      </div>
      <div className="ba__bottom"></div>
    </section>
  );
};

export default BeforeAfter;
