import React from "react";
import { Heading2 } from "../Heading2";
import { linkResolver } from "../../utils/linkResolver";
import { PrismicRichText } from "@prismicio/react";
import { Video } from "../Video";
import Section from "../Section";

export const VideoSection = ({ data: { primary } }) => {
  const { section_title, subline, youtube_video_url, slice_theme } = primary;

  return (
    <Section className={slice_theme} paddingY="py-8 md:py-20 lg:py-24">
      <PrismicRichText
        field={section_title.richText}
        linkResolver={linkResolver}
        components={{
          heading2: ({ children }) => (
            <Heading2 className="sm:text-center">{children}</Heading2>
          ),
        }}
      />
      <p className="text-xl sm:text-center md:text-2xl font-display my-5">
        {subline}
      </p>
      <Video videoLink={youtube_video_url} />
    </Section>
  );
};
