import React, { FC } from "react";
import Section from "../Section";
import SectionText from "../SectionText";
import ResizeScreen from "../ResizeScreen";
import { SliceProps } from "../../types";
import clsx from "clsx";

const ResponsiveSlider: FC<SliceProps> = ({ data: { primary } }) => {
  const { responsiveslider_title, responsiveslider_body, slice_theme } =
    primary;

  // TODO: fix mobile
  return (
    <Section className={clsx("bg-primary inverted text-white", slice_theme)}>
      <div className="flex flex-col">
        <div className="order-1 sm:order-2">
          <SectionText
            label="SOLUTION"
            headline={responsiveslider_title.text}
            text={responsiveslider_body.text}
          />
        </div>

        <div>
          <ResizeScreen />
        </div>
      </div>
    </Section>
  );
};

export default ResponsiveSlider;
