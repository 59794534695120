import "./src/css/styles.css"

import * as React from "react"
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from "gatsby-plugin-prismic-previews"

import IndexPage from "./src/pages"
import Post from "./src/templates/post"
import Work from "./src/templates/work"
import NewWork from './src/templates/new-work';
import DynamicPage from './src/templates/dynamic-page';

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: "stoked",
        linkResolver: doc => {
          // categories
          if (doc?.type === "new_page") return `/${doc?.uid}`

          if (doc?.type === "dynamic_page") {
            if(doc?.data?.path) return `/${doc?.data?.path}/${doc?.uid}`;
            if(doc?.data?.parent) return `/${doc?.data?.parent}/${doc?.uid}`;
            return `/${doc?.uid}`;
          }

          // pages
          if (doc?.type === "post") return `/knowledge/${doc?.uid}`
          if (doc?.type === "work" || doc?.type === "new_work") return `/work/${doc?.uid}`
          if (doc?.type === "notfound") return `/404/${doc?.uid}`

          return "/"
        },
        componentResolver: componentResolverFromMap({
          home: IndexPage,
          post: Post,
          work: Work,
          new_work: NewWork,
          dynamic_page: DynamicPage,
        }),
      },
    ]}
  >
    {element}
  </PrismicPreviewProvider>
)
