import React, { FC, ReactNode } from "react";
import Header from "./Header";
import Main from "./Main";
import Footer from "./Footer";
import { IntersectionObserverContextProvider } from "../contexts/IntersectionObserverContext";

type LayoutProps = {
  children: ReactNode | ReactNode[];
  theme?: string;
  heroTheme?: string;
  transparentBG?: boolean;
};

const Layout: FC<LayoutProps> = ({
  children,
  theme = "theme-purple-400",
  heroTheme,
  transparentBG,
}) => (
  <>
    <Header theme={theme} transparentBG={transparentBG} heroTheme={heroTheme} />
    <Main theme={theme}>
      <IntersectionObserverContextProvider>
        {children}
      </IntersectionObserverContextProvider>
    </Main>
    <Footer theme={theme} />
  </>
);

export default Layout;
