import React, { FC, lazy } from "react";
import { PrismicRichText } from "@prismicio/react";
import { linkResolver } from "../utils/linkResolver";
import { Paragraph } from "./Paragraph";
import Button from "./Button";
import clsx from "clsx";
import GatsbyImage from "./GatsbyImage";

const HeroAnimation = lazy(() => import("./HeroAnimation"));

const HeroTitle = ({ title }) => (
  <>
    {title.richText ? (
      <PrismicRichText
        field={title.richText}
        components={{
          heading1: ({ children }) => (
            <h1 className="text-3xl xs:text-5xl md:text-6xl font-display xl2:pt-2 leading-none text-white">
              {children}
            </h1>
          ),
          strong: ({ children }) => (
            <strong className="text-secondary font-normal">{children}</strong>
          ),
        }}
      />
    ) : (
      <h1 className="text-3xl xs:text-5xl md:text-6xl font-display xl2:pt-2 leading-none text-white">
        {title.text ? title.text : title}
      </h1>
    )}
  </>
);

const HeroIntro = ({ intro }) => (
  <div className="grid grid-cols-1 row-gap-6 leading-snug mt-4">
    <PrismicRichText
      field={intro.richText}
      linkResolver={linkResolver}
      components={{
        paragraph: ({ children }) => (
          <p className="text-sm xs:text-lg mt-9 first:mt-0 text-white">
            {children}
          </p>
        ),
      }}
    />
  </div>
);

type HeroProps = {
  title: any;
  intro?: any;
  imageSVGCode?: any;
  staticImage?: any;
  alignImageBottom?: boolean;
  animation?: string;
  animationFile?: any;
  author?: any;
  buttonLabel?: string;
  buttonLink?: string;
  className?: string;
  theme?: string;
};

export const Hero: FC<HeroProps> = ({
  title,
  intro,
  imageSVGCode,
  staticImage,
  alignImageBottom = true,
  animation,
  animationFile,
  author,
  buttonLabel,
  buttonLink,
  className,
  theme,
}) => (
  <section className={clsx("text-body bg-primary", theme, className)}>
    <div className="container || relative z-0 flex flex-col justify-center md:flex-rowlg:pb-0 md:fit-content max-w-screen-xl2 mx-auto">
      <div className="flex md:gap-10 items-center">
        <div className="pb-16 grow shrink basis-0 pt-28 md:pt-40">
          {title && <HeroTitle title={title} />}
          {intro && <HeroIntro intro={intro} />}
          {author && (
            <Paragraph className="uppercase text-sm md:text-sm text-white">
              By {author.text}
            </Paragraph>
          )}
          {buttonLabel && (
            <Button className="mt-8" to={buttonLink}>
              {buttonLabel}
            </Button>
          )}
        </div>

        {animation || animationFile ? (
          <HeroAnimation
            animation={animation}
            animationFileUrl={animationFile?.url}
          />
        ) : imageSVGCode ? (
          <div
            className={clsx(
              "ml-auto max-w-[507px] hidden lg:block grow shrink",
              alignImageBottom && "self-end"
            )}
            dangerouslySetInnerHTML={{
              __html: imageSVGCode.text,
            }}
          />
        ) : (
          staticImage?.gatsbyImageData && (
            <GatsbyImage
              className="ml-auto relative max-w-[507px] hidden lg:block grow shrink"
              imgClassName="scale-100 !transition-transform !duration-300 !ease-in-out group-hover:scale-[1.02]"
              image={staticImage}
            />
          )
        )}
      </div>
    </div>
  </section>
);
