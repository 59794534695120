import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";

import Facebook from "./Facebook";
import Twitter from "./Twitter";

declare global {
  interface Window {
    dataLayer: any;
  }
}

const Seo = ({
  title,
  desc,
  category,
  visitorValue,
  prismicSeo,
  pageColors,
}) => {
  const { pathname } = useLocation();

  const site = {
    siteMetadata: {
      siteUrl: "https://thisisstoked.com",
      defaultTitle: "STOKED - Digital Products Brought To Life",
      defaultDescription:
        "We break down borders to help you create digital products with impact.",
      defaultBanner: "/images/banner.jpg",
      headline: "Digital Products Brought To Life",
      siteLanguage: "en",
      ogLanguage: "en",
      author: "STOKED",
      twitter: "thisisstoked",
      facebook: "thisisstokedcom",
    },
  };

  const pageTitle = title?.text || null;
  const pageDesc = desc?.text || null;

  // Fallback gatsby-config.js
  const {
    siteMetadata: {
      siteUrl,
      defaultTitle,
      defaultDescription,
      defaultBanner,
      siteLanguage,
      ogLanguage,
      // author,
      // headline,
      twitter,
      facebook,
    },
  } = site;

  const seo = {
    title: prismicSeo?.seo_title || pageTitle || defaultTitle,
    description: prismicSeo?.seo_description || pageDesc || defaultDescription,
    image: `${prismicSeo?.seo_image?.url || siteUrl + defaultBanner}`,
    url: `${siteUrl}${pathname}`,
    category: prismicSeo?.seo_category || category,
    visitorValue: prismicSeo?.seo_visitor_value || visitorValue,
    script: prismicSeo?.seo_script ? prismicSeo.seo_script.text : null,
  };

  const schemaOrgLocalBusiness = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    image: [
      "https://thisisstoked.com/images/1x1/banner1200x1200.jpg",
      "https://thisisstoked.com/images/4x3/banner1200x900.jpg",
      "https://thisisstoked.com/images/16x9/banner1200x675.jpg",
    ],
    "@id": "https://thisisstoked.com/",
    name: "Stoked",
    description: seo.description,
    address: {
      "@type": "PostalAddress",
      streetAddress: "Mejlgade 80, 5. sal",
      addressLocality: "Aarhus",
      postalCode: "8000",
      addressCountry: "DK",
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: 56.161503,
      longitude: 10.214325,
    },
    hasOfferCatalog: {
      "@type": "OfferCatalog",
      name: "Digital Product Development",
      itemListElement: [
        {
          "@type": "Offer",
          itemOffered: {
            "@type": "Service",
            name: "Create Digital",
          },
        },
        {
          "@type": "Offer",
          itemOffered: {
            "@type": "Service",
            name: "Evolve Digital",
          },
        },
        {
          "@type": "Offer",
          itemOffered: {
            "@type": "Service",
            name: "Pioneer Digital",
          },
        },
      ],
    },
    url: seo.url,
    telephone: "+45 25 39 84 38",
    priceRange: "$$",
    openingHours: ["Mo-Fr 9:00-17:00"],
  };

  const schemaOrgLogo = {
    "@context": "https://schema.org",
    "@type": "Organization",
    url: "https://thisisstoked.com/",
    logo: "https://thisisstoked.com/images/logo.png",
  };

  const schemaOrgWebPage = {
    "@context": "https://schema.org/",
    "@type": "WebPage",
    name: seo.title,
    speakable: {
      "@type": "SpeakableSpecification",
      xpath: [
        "/html/head/title",
        "/html/head/meta[@name='description']/@content",
      ],
    },
    url: seo.url,
  };

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({
      pageTitle: seo.title,
      pageCategory: seo.category,
      visitorValue: seo.visitorValue,
    });
  }, [seo.title, seo.category, seo.visitorValue]);

  return (
    <>
      <Helmet title={`${seo.title} | STOKED`} async>
        <html lang={siteLanguage} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />

        <meta
          name="google-site-verification"
          content="SrsVq70Rj1s9te9X6C-H4uBLErbMJLJ5lkMf_5EO5CA"
        />
        <meta
          name="facebook-domain-verification"
          content="yozb7rnlbg7fezsxxt942orikmpb7h"
        />

        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/favicon/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon/favicon-16x16.png"
        />
        <link rel="manifest" href="/favicon/site.webmanifest" />
        <link
          rel="mask-icon"
          href="/favicon/safari-pinned-tab.svg"
          color="#5bbad5"
        />

        {!seo.script && (
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgLocalBusiness)}
          </script>
        )}
        {!seo.script && (
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgLogo)}
          </script>
        )}
        {!seo.script && (
          <script type="application/ld+json">
            {JSON.stringify(schemaOrgWebPage)}
          </script>
        )}
        {seo.script && (
          <script type="application/ld+json">
            {JSON.stringify(seo.script)}
          </script>
        )}

        {pageColors?.primary.primary_color && (
          <style type="text/css">{`
            .t-page {
              --color-primary: ${pageColors?.primary.primary_color}
            }
          `}</style>
        )}

        {pageColors?.primary.secondary_color && (
          <style type="text/css">{`
            .t-page {
              --color-secondary: ${pageColors?.primary.secondary_color}
            }
          `}</style>
        )}

        {pageColors?.primary.secondary_color_hover && (
          <style type="text/css">{`
            .t-page {
              --color-secondary-hover: ${pageColors?.primary.secondary_color_hover}
            }
          `}</style>
        )}

        {pageColors?.primary.tertiary_color && (
          <style type="text/css">{`
            .t-page {
              --color-tertiary: ${pageColors?.primary.tertiary_color}
            }
          `}</style>
        )}
      </Helmet>

      <Facebook
        desc={seo.description}
        image={seo.image}
        title={seo.title}
        // type={article ? "article" : "website"}
        url={seo.url}
        locale={ogLanguage}
        name={facebook}
      />
      <Twitter
        title={seo.title}
        image={seo.image}
        desc={seo.description}
        username={twitter}
      />
    </>
  );
};
export default Seo;

Seo.defaultProps = {
  prismic: {
    seo_title: null,
    seo_description: null,
    seo_image: null,
    seo_category: null,
    seo_visitor_value: "low",
    seo_script: null,
  },
  title: null,
  desc: null,
  category: null,
  visitorValue: null,
  prismicSeo: null,
  pageColors: null,
  // article: false,
  // node: null,
};
