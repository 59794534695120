import React from "react";
import Section from "../Section";
import { graphql, useStaticQuery } from "gatsby";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";
import { BlogPost } from "../BlogPost";

const Blog = () => {
  const data = useStaticQuery(postsQuery);
  const {
    data: { allPrismicPost },
  } = useMergePrismicPreviewData(data);

  return (
    <Section>
      <BlogPosts posts={allPrismicPost.edges} />
    </Section>
  );
};

const BlogPosts = ({ posts }) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-16 md:gap-y-20 mb-10 md:mb-20">
      {posts
        .filter(d => !!d?.node?.uid)
        .map(({ node }) => {
          return (
            <BlogPost
              key={node.uid}
              title={node.data.title}
              author={node.data.author}
              description={node.data.description}
              image={node.data.image}
              imageSVG={node.data.svg_image}
              postTheme={node.data.page_theme}
              meta={node}
            />
          );
        })}
    </div>
  );
};

const postsQuery = graphql`
  query PostsQuery {
    allPrismicPost(sort: { first_publication_date: DESC }) {
      edges {
        node {
          uid
          type
          data {
            title {
              text
            }
            author {
              text
            }
            description {
              text
            }
            image {
              gatsbyImageData
            }
            svg_image {
              gatsbyImageData
            }
            page_theme
          }
        }
      }
    }
  }
`;

export default Blog;
