import React from "react";

export const Lupe = ({ className }) => (
  <svg
    className={className || null}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" rx="8" fill="#B5F0FF" />
    <path
      d="M18.5 27C23.1944 27 27 23.1944 27 18.5C27 13.8056 23.1944 10 18.5 10C13.8056 10 10 13.8056 10 18.5C10 23.1944 13.8056 27 18.5 27Z"
      stroke="#333333"
      strokeWidth="2"
      strokeLinejoin="round"
    />
    <path
      d="M24.6113 24.6108L28.854 28.8535"
      stroke="#333333"
      strokeWidth="2"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
);
