import React, { useMemo } from "react";

export const Video = ({ videoLink }) => {
  const videoId = useMemo(() => {
    const videoIdRegex = /[?&]v=([^&#]+)/;
    const match = videoLink.match(videoIdRegex);

    if (match && match[1]) {
      return match[1];
    } else {
      return null;
    }
  }, [videoLink]);

  return (
    <iframe
      width="1008"
      height="567"
      className="mx-auto my-6 max-w-full border-none"
      src={`https://www.youtube.com/embed/${videoId}?feature=oembed&rel=0&enablejsapi=1&controls=0`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
      title="Stoked | Digital Product Agency"
    />
  );
};
