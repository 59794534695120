import React from "react";

const HamburgerOpen = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M0.358433 21.2421L2.76086 23.6446C2.95712 23.8409 4.18346 22.9327 5.49997 21.6161L21.616 5.49911C22.9325 4.18252 23.8407 2.95611 23.6444 2.75984L21.242 0.357274C21.0458 0.161009 19.8194 1.06922 18.5029 2.38581L2.38685 18.5028C1.07033 19.8194 0.162179 21.0458 0.358433 21.2421Z"
        fill="#FEFEFE"
      />
      <path
        d="M2.75778 0.355343L0.355349 2.75791C0.159095 2.95418 1.06725 4.18059 2.38376 5.49718L18.4998 21.6142C19.8163 22.9308 21.0427 23.839 21.2389 23.6427L23.6414 21.2401C23.8376 21.0439 22.9295 19.8175 21.6129 18.5009L5.49689 2.38387C4.18037 1.06728 2.95403 0.159078 2.75778 0.355343Z"
        fill="#FEFEFE"
      />
      <path
        d="M15.5357 13.1498L13.1357 15.5523C12.9397 15.7486 13.1938 16.3212 13.7033 16.8312L19.9402 23.075C20.4497 23.585 21.0217 23.8394 21.2177 23.6431L23.6177 21.2406C23.8137 21.0443 23.5596 20.4717 23.0502 19.9617L16.8132 13.7179C16.3037 13.2079 15.7318 12.9535 15.5357 13.1498Z"
        fill="currentColor"
        className="text-primary"
      />
      <path
        d="M20.5601 18.1527L18.1602 20.5553C17.9641 20.7515 17.9628 21.0685 18.1573 21.2631L20.5378 23.6462C20.7322 23.8409 21.0488 23.8396 21.2449 23.6433L23.6448 21.2408C23.8409 21.0445 23.8422 20.7276 23.6477 20.5329L21.2672 18.1498C21.0727 17.9552 20.7562 17.9564 20.5601 18.1527Z"
        fill="currentColor"
        className="text-secondary-hover"
      />
    </svg>
  );
};

export default HamburgerOpen;
