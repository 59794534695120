import React from "react";
import { useEffect } from "react";
import Section from "../Section";
import { PrismicRichText } from "@prismicio/react";
import { linkResolver } from "../../utils/linkResolver";
import { Heading2 } from "../Heading2";
import { Video } from "../Video";
import clsx from "clsx";
import { buttonCls } from "../Button";

export const TallyEmbeddedForm = ({ data: { primary } }) => {
  const {
    section_title,
    subline,
    main_content,
    tally_form_id,
    popup,
    popup_button_text,
    slice_theme,
  } = primary;

  useEffect(() => {
    const widgetScriptSrc = "https://tally.so/widgets/embed.js";

    const load = () => {
      if (popup) return;

      // Load Tally embeds
      if (typeof Tally !== "undefined") {
        Tally.loadEmbeds();
        return;
      }

      // Fallback if window.Tally is not available
      document
        .querySelectorAll("iframe[data-tally-src]:not([src])")
        .forEach(iframeEl => {
          (iframeEl as HTMLIFrameElement).src =
            (iframeEl as HTMLIFrameElement).dataset.tallySrc || "";
        });
    };

    // If Tally is already loaded, load the embeds
    if (typeof Tally !== "undefined") {
      load();
      return;
    }

    // If the Tally widget script is not loaded yet, load it
    if (document.querySelector(`script[src="${widgetScriptSrc}"]`) === null) {
      const script = document.createElement("script");
      script.src = widgetScriptSrc;
      script.onload = load;
      script.onerror = load;
      document.body.appendChild(script);
      return;
    }
  }, []);

  return (
    <Section className={slice_theme} paddingY="py-8 md:py-20 lg:py-24">
      <div className={clsx("block md:gap-8", !popup && "md:flex")}>
        <div
          className={clsx(
            "md:flex-1",
            popup ? "text-center" : "md:max-w-1/2 md:w-1/2"
          )}
        >
          <PrismicRichText
            field={section_title.richText}
            linkResolver={linkResolver}
            components={{
              heading2: ({ children }) => (
                <Heading2
                  className={clsx(popup && "ml-0", popup && "max-w-none")}
                >
                  {children}
                </Heading2>
              ),
            }}
          />
          <p className="text-xl md:text-2xl font-display my-5">{subline}</p>
          <PrismicRichText
            field={main_content.richText}
            linkResolver={linkResolver}
            components={{
              embed: ({ node }) =>
                node.oembed.type === "video" ? (
                  <Video videoLink={node.oembed.embed_url} />
                ) : null,
              hyperlink: ({ children, node }) => (
                <a
                  className="text-secondary"
                  href={linkResolver(node.data)}
                  target={
                    node.data.link_type === "Document" ? "_self" : "_blank"
                  }
                >
                  {children}
                </a>
              ),
            }}
          />
          {popup && (
            <div className="mt-8 flex justify-center">
              <button
                className={buttonCls}
                data-tally-open={tally_form_id}
                data-tally-emoji-text="👋"
                data-tally-emoji-animation="wave"
                data-tally-layout="modal"
                data-tally-width="640"
              >
                {popup_button_text !== null ? popup_button_text : "Open Form"}
              </button>
            </div>
          )}
        </div>
        {!popup && (
          <div className="md:flex-1 md:max-w-1/2 md:w-1/2 mt-12 md:mt-0">
            <iframe
              data-tally-src={`https://tally.so/embed/${tally_form_id}?alignLeft=1&hideTitle=1&transparentBackground=1&dynamicHeight=1`}
              loading="lazy"
              width="100%"
              height="216"
              frameBorder={0}
              marginHeight={0}
              marginWidth={0}
              title="Newsletter subscribers"
            ></iframe>
          </div>
        )}
      </div>
    </Section>
  );
};
