import { PrismicRichText } from "@prismicio/react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";
import React from "react";
import { uid } from "uid";
import { linkResolver } from "../../utils/linkResolver";
import Button from "../Button";
import { Heading2 } from "../Heading2";
import { Paragraph } from "../Paragraph";
import Section from "../Section";
import { ServiceCard } from "./ServiceCard";
import clsx from "clsx";

export const ServicesCards = ({ data: { primary } }) => {
  const {
    section_title,
    section_body,
    button_label,
    services_page_link,
    slice_theme,
  } = primary;
  const servicesData = useStaticQuery(servicesCardsQuery);
  const {
    data: { allPrismicServiceCategory },
  } = useMergePrismicPreviewData(servicesData);

  const { edges: services } = allPrismicServiceCategory;

  return (
    <Section
      paddingY={clsx("py-16 sm:py-20 lg:py-24 bg-grey-100", slice_theme)}
    >
      <PrismicRichText
        field={section_title.richText}
        components={{
          heading2: ({ children }) => (
            <Heading2 className="sm:text-center">{children}</Heading2>
          ),
          strong: ({ children }) => (
            <strong className="text-primary font-normal">{children}</strong>
          ),
        }}
      />
      <PrismicRichText
        field={section_body.richText}
        components={{
          paragraph: ({ children }) => (
            <Paragraph className="sm:text-center max-w-4xl mx-auto">
              {children}
            </Paragraph>
          ),
        }}
      />
      <div className="lg:flex mt-10 gap-12">
        {services.map(({ node: { data: service, uid: slug } }) => {
          const { title, description } = service;

          return (
            <ServiceCard
              key={uid(16)}
              title={title}
              description={description}
              link={`${linkResolver(services_page_link)}#${slug}`}
              theme={slice_theme}
            />
          );
        })}
      </div>
      <div className="mt-16 lg:mt-16 flex justify-center">
        <Button linkMeta={services_page_link}>{button_label}</Button>
      </div>
    </Section>
  );
};

const servicesCardsQuery = graphql`
  query ServicesCardsQuery {
    allPrismicServiceCategory {
      edges {
        node {
          data {
            title {
              richText
            }
            description {
              richText
            }
          }
          uid
        }
      }
    }
  }
`;
