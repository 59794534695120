export const getSeo = (body) => {
  if (!body) return

  return body.find(item => item.slice_type === "seo")?.primary
}

export const getSliceData = (body, type) => {
  if (!body) return

  return body.find(item => item.slice_type === type)
}
