import React, { useEffect, useRef, useState } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";
import { linkResolver } from "../utils/linkResolver";
import Icon from "../icon";
import clsx from "clsx";

const checkHasHamburguer = () =>
  typeof window !== "undefined" && window.innerWidth < 1080;

const linkClassName = "block px-10 lg:px-6 pt-4 relative py-2";

const Dropdown = ({ active, links, onClick, isPartiallyActive }) => {
  return (
    <div
      className={`text-left lg:bg-white lg:text-primary lg:font-medium lg:-mt-4 lg:absolute transition-all duration-300 origin-top max-w-[200px] ${
        active
          ? "lg:visible lg:opacity-100 lg:scale-y-100"
          : "lg:invisible lg:opacity-0 lg:scale-y-50"
      }`}
    >
      <ul className="pl-8 pr-5 pb-3 lg:pl-4">
        {links.map(({ link: sublink, label: sublabel, parent }) => {
          return (
            <li>
              <Link
                className={linkClassName}
                to={linkResolver(sublink, parent)}
                getProps={isPartiallyActive}
                onClick={onClick}
              >
                <span>{sublabel}</span>
                <span className="nav__dot dot-sm bg-secondary-hover lg:absolute"></span>
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

const MainNavigation = ({ navigationLinks, onClick, theme }) => {
  const [activeDropdown, setActiveDropdown] = useState<null | string>(null);

  const removeActiveDropdown = () => {
    setActiveDropdown(null);
  };

  const isPartiallyActive = ({
    isPartiallyCurrent,
    href,
    location,
  }): { className?: string } => {
    if (href !== location.pathname && href === "/") return {};
    return isPartiallyCurrent
      ? { className: clsx(linkClassName, "is-active") }
      : {};
  };

  return (
    <nav
      className={clsx(
        "nav absolute top-[var(--height-header)] l-0 w-full max-h-full overflow-y-auto hidden xl:block text-body",
        "lg:relative lg:top-auto lg:left-[unset] lg:min-h-[unset] lg:width-[unset] lg:overflow-y-visible",
        theme
      )}
    >
      <div className="nav__inner">
        <ul className="container flex flex-col py-4 lg:flex-row lg:space-y-0 px-0">
          {Object.keys(navigationLinks).map(key => {
            const { link, label, links } = navigationLinks[key];

            const hasDropdown = !!links;

            return (
              <li
                key={link?.uid}
                className="relative uppercase transition duration-300 ease-in-out whitespace-nowrap text-white"
                onMouseEnter={
                  hasDropdown ? () => setActiveDropdown(label) : undefined
                }
                onMouseLeave={hasDropdown ? removeActiveDropdown : undefined}
              >
                <Link
                  className={linkClassName}
                  to={linkResolver(link)}
                  getProps={isPartiallyActive}
                  onClick={onClick}
                >
                  <span className="nav__text">{label}</span>
                  <span className="nav__dot dot-sm bg-secondary-hover"></span>
                </Link>
                {links && (
                  <Dropdown
                    active={activeDropdown}
                    links={links}
                    onClick={onClick}
                    isPartiallyActive={isPartiallyActive}
                  />
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </nav>
  );
};

const getHierarchicalNavigationLinksData = navigationLinks => {
  const hierarchicalNavigationLinksData = {};

  navigationLinks.forEach(navigationLink => {
    if (!navigationLink.parent) {
      hierarchicalNavigationLinksData[navigationLink.link.uid] = Object.assign(
        {},
        hierarchicalNavigationLinksData[navigationLink.link.uid],
        navigationLink
      );
    } else {
      if (!hierarchicalNavigationLinksData[navigationLink.parent])
        hierarchicalNavigationLinksData[navigationLink.parent] = { links: [] };

      if (!hierarchicalNavigationLinksData[navigationLink.parent].links)
        hierarchicalNavigationLinksData[navigationLink.parent].links = [];

      hierarchicalNavigationLinksData[navigationLink.parent].links.push(
        navigationLink
      );
    }
  });

  return hierarchicalNavigationLinksData;
};

const Header = ({ theme = "theme-purple-400", transparentBG, heroTheme }) => {
  const data = useStaticQuery(navigationQuery);
  const {
    data: { allPrismicNewNavigation },
  } = useMergePrismicPreviewData(data);

  const [open, setOpen] = useState(false);
  const hasHamburguer = useRef(checkHasHamburguer());

  const hierarchicalNavigationLinksData = getHierarchicalNavigationLinksData(
    allPrismicNewNavigation.edges[0].node.data.navigation_links
  );

  const handleResize = () => {
    hasHamburguer.current = checkHasHamburguer();
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize, false);

    return () => {
      window.removeEventListener("resize", handleResize, false);
    };
  }, []);

  const handleClick = () => {
    if (!open && hasHamburguer.current) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    setOpen(!open);
  };

  return (
    <header
      className={clsx(
        theme,
        !transparentBG && "bg-primary",
        "text-white text-base absolute w-full top-0 z-50 t-page"
      )}
    >
      <div className="header container relative">
        <div className="z-50 flex justify-between items-center w-full">
          <Link to="/">
            <Icon
              name="brand"
              theme={heroTheme || theme}
              className="brand brand--header"
            />
          </Link>

          {/* eslint-disable-next-line */}
          <label
            htmlFor="nav"
            className="p-5 -mr-5 cursor-pointer lg:hidden"
            onClick={handleClick}
          >
            <div className="flex">
              <Icon
                className="hamburger w-4 h-4"
                name={open ? "hamburger-open" : "hamburger-closed"}
              />
            </div>
          </label>
        </div>

        {/* eslint-disable */}
        <input type="checkbox" id="nav" className="hidden" />

        <MainNavigation
          navigationLinks={hierarchicalNavigationLinksData}
          onClick={handleClick}
          theme={theme}
        />
      </div>
    </header>
  );
};

const navigationQuery = graphql`
  query navigationQuery {
    allPrismicNewNavigation {
      edges {
        node {
          id
          type
          data {
            navigation_links {
              label
              link {
                url
                uid
                type
              }
            }
          }
        }
      }
    }
  }
`;

export default Header;
