import React from "react";
import GatsbyImage from "../GatsbyImage";

const Image = ({ data: { primary } }) => {
  const { post_image, post_image_caption } = primary;

  return (
    <figure className=" max-w-2xl py-6 sm:py-10 mx-auto">
      <GatsbyImage image={post_image} className="bg-img rounded-md" />
      {post_image_caption && (
        <figcaption className="max-w-sm pt-5 text-sm italic">
          {post_image_caption.text}
        </figcaption>
      )}
    </figure>
  );
};

export default Image;
