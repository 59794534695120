import React, { useEffect, useRef, useState } from "react";

const backToZeroDelayMs = 50;

export const usePageScrollDown = (
  onScrollCallback,
  onStopCallback,
  paused = false
) => {
  const timerRef = useRef<number | null>(null);
  const lastScrollTopRef = useRef(0);

  function setTimer() {
    if (timerRef.current !== null) clearTimeout(timerRef.current);
    timerRef.current = window.setTimeout(function () {
      onStopCallback();
    }, backToZeroDelayMs);
  }

  function pause() {
    window.removeEventListener("scroll", handleScroll, false);
    if (timerRef.current !== null) clearTimeout(timerRef.current);
  }

  function restart() {
    window.addEventListener("scroll", handleScroll, false);
  }

  const handleScroll = () => {
    var st = window.pageYOffset || document.documentElement.scrollTop;
    let deltaScroll = lastScrollTopRef.current - st;

    if (deltaScroll >= 0) {
      lastScrollTopRef.current = Math.max(st, 0);
      return;
    }

    onScrollCallback(deltaScroll);

    setTimer();

    lastScrollTopRef.current = Math.max(st, 0);
  };

  useEffect(() => {
    if (!paused) {
      window.addEventListener("scroll", handleScroll, false);
    }

    return () => {
      window.removeEventListener("scroll", handleScroll, false);
      if (timerRef.current !== null) clearTimeout(timerRef.current);
    };
  }, []);

  return [pause, restart];
};
