import React from "react";
import { graphql } from "gatsby";
import { getSeo, getSliceData } from "../utils/helpers";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import Layout from "../components/Layout";
import SlicesGeneral from "../components/SlicesGeneral";
import Seo from "../components/SEO/SEO";

const DynamicPage = ({ data }) => {
  const page = data?.prismicDynamicPage?.data;
  if (!page) return <div>loading...</div>;

  const theme = page.page_theme || "theme-purple-400";
  const { title, description } = page;

  return (
    <Layout
      theme={theme}
      heroTheme={getSliceData(page?.body, "hero")?.primary.slice_theme}
      transparentBG={true}
    >
      <Seo
        title={title}
        desc={description}
        category={page?.uid}
        visitorValue="high"
        prismicSeo={getSeo(page?.body)}
        pageColors={getSliceData(page?.body, "theme_slice")}
      />
      <div id="content">
        <SlicesGeneral allSlices={page.body} theme={theme} pageTitle={title} />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query PageQuery($uid: String) {
    prismicDynamicPage(uid: { eq: $uid }) {
      _previewable
      uid
      id
      type
      data {
        page_theme
        title {
          text
          richText
        }
        description {
          text
        }
        body {
          ... on PrismicDynamicPageDataBodySeo {
            id
            slice_type
            primary {
              seo_title
              seo_description
              seo_category
              seo_visitor_value
              seo_script {
                text
              }
              seo_image {
                url
              }
            }
          }
          ... on PrismicDynamicPageDataBodyThemeSlice {
            id
            slice_type
            primary {
              primary_color
              secondary_color
              secondary_color_hover
              tertiary_color
            }
          }
          ... on PrismicDynamicPageDataBodyText {
            id
            slice_type
            primary {
              text_label {
                text
                richText
              }
              text_title {
                text
                richText
              }
              text_rich_title {
                richText
              }
              text_title_size
              text_body_size
              text_variant
              slice_theme
            }
            items {
              text_body {
                text
                richText
              }
            }
          }
          ... on PrismicDynamicPageDataBodyLogosSlider {
            id
            slice_type
            primary {
              slice_theme
            }
          }
          ... on PrismicDynamicPageDataBodyHero {
            id
            slice_type
            primary {
              hero_title {
                text
                richText
              }
              hero_use_page_title
              hero_intro {
                richText
                text
              }
              hero_image_svg_code {
                text
              }
              hero_cta_label
              hero_animation_file {
                url
              }
              hero_animation
              hero_cta_link {
                url
              }
              hero_show_logos_slider
              hero_logos_slider_theme
              slice_theme
            }
          }
          ... on PrismicDynamicPageDataBodyBulletsSection {
            id
            slice_type
            items {
              bullet_text
            }
            primary {
              section_title {
                richText
              }
              section_content {
                richText
              }
              slice_theme
            }
          }
          ... on PrismicDynamicPageDataBodyCta {
            id
            slice_type
            primary {
              cta_title {
                text
              }
              cta_body {
                text
                richText
              }
              cta_text {
                text
              }
              cta_link {
                url
                type
                link_type
                target
                slug
                uid
              }
              slice_theme
              visible
            }
          }
          ... on PrismicDynamicPageDataBodyFullWidthImage {
            id
            slice_type
            primary {
              image {
                gatsbyImageData
              }
            }
          }
          ... on PrismicDynamicPageDataBodyNewText {
            id
            slice_type
            primary {
              new_text_label: text_label
              new_text_title: text_title {
                text
                richText
              }
              text_centered
              text_layout
              text_remove_padding_bottom
              text_span_title
              slice_gray_background
              slice_inverted_colours
              slice_theme
            }
            items {
              column_body {
                text
                richText
              }
            }
          }
          ... on PrismicDynamicPageDataBodyQuote {
            id
            slice_type
            primary {
              quote_title {
                text
                richText
              }
              quote_name {
                text
                richText
              }
              quote_body {
                text
                richText
              }
              quote_image {
                alt
                gatsbyImageData
              }
              slice_gray_background
              slice_inverted_colours
              slice_theme
            }
          }
          ... on PrismicDynamicPageDataBodyImagesSlider {
            id
            slice_type
            primary {
              slice_gray_background
              slice_inverted_colours
              slice_theme
            }
            items {
              images_slider_item_image {
                gatsbyImageData
              }
            }
          }
          ... on PrismicDynamicPageDataBodyMobilesidebyside {
            slice_type
            primary {
              mobilesidebyside_title {
                text
                richText
              }
              mobilesidebyside_body {
                text
                richText
              }
              mobilesidebyside_has_text_top
            }
            items {
              mobilesidebyside_item_image {
                gatsbyImageData
              }
            }
          }
          ... on PrismicDynamicPageDataBodyNewSolutionCarousel {
            slice_type
            primary {
              solution_carousel_title {
                text
                richText
              }
              solution_carousel_body {
                text
                richText
              }
              slice_gray_background
              slice_inverted_colours
              slice_theme
            }
            items {
              solution_carousel_item_image {
                gatsbyImageData
              }
              solution_carousel_item_description {
                text
                richText
              }
            }
          }
          ... on PrismicDynamicPageDataBodyResponsiveslider {
            slice_type
            primary {
              responsiveslider_title {
                text
                richText
              }
              responsiveslider_body {
                text
                richText
              }
              slice_theme
            }
            items {
              responsiveslider_item_image {
                gatsbyImageData
              }
            }
          }
          ... on PrismicDynamicPageDataBodyServicesCards {
            id
            slice_type
            primary {
              section_title {
                richText
              }
              button_label
              section_body {
                richText
              }
              services_page_link {
                type
                uid
              }
              slice_theme
            }
          }
          ... on PrismicDynamicPageDataBodyWhyChooseUs {
            id
            slice_type
            primary {
              slice_theme
            }
          }
          ... on PrismicDynamicPageDataBodyPagelist {
            id
            slice_type
            primary {
              page_list_type
              page_list_title {
                text
                richText
              }
              page_list_body {
                text
                richText
              }
              slice_theme
            }
            items {
              page_list_item_title {
                text
                richText
              }
              page_list_item_label {
                text
                richText
              }
              page_list_item_link {
                uid
                type
                type
                link_type
                document {
                  ... on PrismicWork {
                    data {
                      title {
                        text
                        richText
                      }
                      description {
                        text
                        richText
                      }
                      image {
                        gatsbyImageData
                        large: gatsbyImageData
                      }
                    }
                  }
                  ... on PrismicNewWork {
                    data {
                      title {
                        text
                        richText
                      }
                      description {
                        text
                        richText
                      }
                      image: featured_image {
                        gatsbyImageData
                        large: gatsbyImageData
                      }
                      hero_background_image {
                        gatsbyImageData
                        large: gatsbyImageData
                      }
                      background_as_thumbnail
                    }
                  }
                  ... on PrismicCareer {
                    data {
                      title {
                        text
                        richText
                      }
                      description {
                        text
                        richText
                      }
                    }
                  }
                  ... on PrismicPost {
                    data {
                      title {
                        text
                        richText
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicDynamicPageDataBodyTeam {
            slice_type
            primary {
              section_title {
                text
              }
              section_body {
                richText
              }
            }
          }
          ... on PrismicDynamicPageDataBodyClutchReviews {
            id
            slice_type
          }
          ... on PrismicDynamicPageDataBodyDynamicForm {
            id
            slice_type
            primary {
              form_name
              section_title {
                richText
              }
              subline
              main_content {
                richText
              }
              slice_theme
            }
            items {
              name
              type
              placeholder
              required
            }
          }
          ... on PrismicDynamicPageDataBodyCalendly {
            id
            slice_type
            primary {
              section_title {
                richText
              }
              subline
              main_content {
                richText
              }
              calendly_url
              slice_theme
              slice_layout
            }
          }
          ... on PrismicDynamicPageDataBodyYoutubeVideo {
            id
            slice_type
            primary {
              section_title {
                richText
              }
              subline
              youtube_video_url
            }
          }
          ... on PrismicDynamicPageDataBodyTallyEmbeddedForm {
            id
            slice_type
            primary {
              section_title {
                richText
              }
              subline
              main_content {
                richText
              }
              tally_form_id
              popup
              popup_button_text
              slice_theme
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(DynamicPage);
