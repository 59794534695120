import React from "react";
import GatsbyImage from "../GatsbyImage";
import Section from "../Section";

export const FullWidthImage = ({ data: { primary } }) => {
  const { image } = primary;

  return (
    <Section fullWidth={true} paddingY="!px-0 sm:!px-0 lg:!px-0">
      <GatsbyImage image={image} imgClassName="w-full" />
    </Section>
  );
};
