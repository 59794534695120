import React, {
  MouseEvent,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { uid } from "uid";
import GatsbyImage from "../GatsbyImage";
import clsx from "clsx";
import { createPortal } from "react-dom";
import Icon from "../../icon";

const LightboxImage = ({ imageUrl, onClose, show }) => {
  const modalRef: MutableRefObject<HTMLDivElement | null> = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [canCreatePortal, setCanCreatePortal] = useState(false);

  const setDocumentScrollEnabled = enabled => {
    if (typeof document === "undefined") return;

    if (enabled) {
      document.body.classList.remove("overflow-hidden");
      return;
    }

    document.body.classList.add("overflow-hidden");
  };

  useEffect(() => {
    if (typeof document !== "undefined") setCanCreatePortal(true);
  }, []);

  useEffect(() => {
    if (!imageUrl.gatsbyImageData) return;

    if (show) {
      setDocumentScrollEnabled(false);
      setIsOpen(true);
      modalRef.current!.style.opacity = "1";
      return;
    }

    if (!show) {
      modalRef.current!.style.opacity = "0";
      setTimeout(() => {
        setIsOpen(false);
        setDocumentScrollEnabled(true);
      }, 300);
      return;
    }
  }, [show]);

  const onModalClick = (e: MouseEvent) => {
    if (e.target === e.currentTarget) onClose();
  };

  return (
    <>
      {canCreatePortal &&
        createPortal(
          <div
            id="image-lightbox"
            ref={modalRef}
            className={clsx(
              "fixed z-[999999] top-0 left-0 w-screen h-screen flex justify-center items-center bg-black/80 transition-all duration-300 opacity-0",
              isOpen ? "visible" : "invisible"
            )}
            onClick={onModalClick}
          >
            <button
              onClick={onClose}
              className="text-white absolute right-6 md:right-20 top-5"
            >
              <Icon name="close" className="w-[24px] h-[24px]" />
            </button>
            <div className="h-[calc(100vh-120px)]">
              <GatsbyImage
                image={imageUrl}
                className="h-full !overflow-x-auto md:object-none flex"
                imgClassName="!h-full min-h-full !w-auto md:!w-full md:min-h-full md:!object-contain md:min-h-none !mx-auto !max-w-fit !px-6 md:px-0"
              />
            </div>
          </div>,
          document.body
        )}
    </>
  );
};

export const ImagesSlider = ({ data: { primary, items } }) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");

  const { slice_gray_background, slice_inverted_colours, slice_theme } =
    primary;

  const openImageModal = imageUrl => {
    setSelectedImageUrl(imageUrl);
    setShowModal(true);
  };

  return (
    <section
      className={clsx(
        "pb-8 md:pb-12 pt-8",
        slice_gray_background && "bg-gray-100",
        slice_inverted_colours && "bg-primary text-white inverted",
        slice_theme
      )}
    >
      <LightboxImage
        imageUrl={selectedImageUrl}
        show={showModal}
        onClose={() => {
          setShowModal(false);
        }}
      />

      {items && (
        <Swiper
          breakpoints={{
            1280: {
              spaceBetween: 30,
              slidesOffsetBefore: 64,
              slidesOffsetAfter: 64,
            },
            786: {
              spaceBetween: 30,
              slidesOffsetBefore: 32,
              slidesOffsetAfter: 32,
            },
            320: {
              spaceBetween: 10,
              slidesOffsetBefore: 16,
              slidesOffsetAfter: 16,
            },
          }}
          loop
          slidesPerView="auto"
          centeredSlides
          grabCursor
          slideToClickedSlide
          pagination={{
            clickable: true,
            renderBullet: (index, className) => {
              return `<span class="${className}"></span>`;
            },
          }}
        >
          {items
            .filter((i: any) => !!i.images_slider_item_image)
            .map(({ images_slider_item_image }, index) => (
              <SwiperSlide
                key={uid(16)}
                className="w-[83%] md:w-[75%] lg:w-[47.75%] group"
              >
                {({ isActive }) => {
                  return (
                    <div
                      className="relative"
                      onClick={
                        isActive
                          ? () => {
                              openImageModal(images_slider_item_image);
                            }
                          : undefined
                      }
                    >
                      <GatsbyImage
                        image={images_slider_item_image}
                        className="h-full w-full object-cover bg-gray-200 rounded-md"
                      />
                      <div className="absolute bottom-2 left-2 transition-opacity duration-300 opacity-0 group-[.swiper-slide-active]:opacity-100">
                        <Icon name="lupe" className="w-[40px] h-[40px]" />
                      </div>
                    </div>
                  );
                }}
              </SwiperSlide>
            ))}
        </Swiper>
      )}
    </section>
  );
};
