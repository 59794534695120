import React from "react";
import { PrismicRichText } from "@prismicio/react";

const Youtube = ({
  data: {
    primary: { start, url },
  },
}) => {
  return (
    <>
      {<PrismicRichText field={url} /> && (
        <div className="max-w-2xl py-6 sm:py-10 mx-auto">
          <div className="youtube">
            <iframe
              title="Youtube"
              src={`${(<PrismicRichText field={url} />)}${
                start ? `?start=${(<PrismicRichText field={start} />)}` : ""
              }`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}
    </>
  );
};

export default Youtube;
