import clsx from "clsx";
import React from "react";
import { uid } from "uid";
import GatsbyImage from "./GatsbyImage";

const teamCardDimensions = "w-[126px] h-[126px] md:w-72 md:h-72";

export const TeamCard = ({ image, name, skills }) => (
  <div key={uid(16)} className="md:place-self-center flex md:block">
    <div
      className={clsx(
        "group relative",
        "before:hidden md:before:block before:absolute before:-z-[1] before:top-0 before:left-0 before:w-full before:h-full before:rounded-team before:bg-primary before:opacity-50 before:transform before:duration-500 before:delay-150 before:ease-in-out before:transition-transform before:-translate-x-[0%] hover:before:-translate-x-[10%]",
        "after:hidden md:after:block after:absolute after:-z-[2] after:top-0 after:left-0 after:w-full after:h-full after:rounded-team after:bg-primary after:opacity-80 after:-translate-x-[10%]"
      )}
    >
      <div
        className={clsx(
          teamCardDimensions,
          "bg-img rounded-md md:rounded-team",
          "transform duration-500 ease-in-out transition-transform md:translate-x-[10%] md:group-hover:-translate-x-[10%]"
        )}
        style={{ willChange: "transform" }}
      >
        <GatsbyImage
          image={image}
          className="bg-img rounded-md md:rounded-team"
        />
      </div>
    </div>

    <div className="ml-2 md:-ml-[10%] md:pt-8 md:pb-6 place-self-center">
      {name.text && (
        <h2 className="text-2xl font-display leading-tight">{name.text}</h2>
      )}

      {skills.text && (
        <p className="mt-2 md:mt-0 text-sm md:text-lg">{skills.text}</p>
      )}
    </div>
  </div>
);
