import React from "react";
import { uid } from "uid";

import {
  Cta,
  ContactSection as Contact,
  Blog,
  Team,
  Text,
  Quote,
  LogosSlider,
  BulletsSection,
  PageList,
  WhyChooseUs,
  OurValues,
  ServicesCards,
  ServicesSection,
  ExtendedTeamSection,
  HeroSlice,
  FullWidthImage,
  ImagesSlider,
  NewText,
  DynamicForm,
  CalendlySection,
  VideoSection,
  TallyEmbeddedForm,
} from "./sections";
import { ClutchReviews } from "./sections/ClutchReviews";
import { MobileSideBySide, NewSolutionCarousel, ResponsiveSlider } from './work';

const SlicesGeneral = ({
  allSlices,
  theme,
  pageTitle,
}) => {
  if (!allSlices) return null;

  return allSlices.map(slice => {
    if(slice?.primary?.visible === false) return null;

    switch (slice.slice_type) {
      case "hero":
        return <HeroSlice key={uid(16)} data={slice} pageTitle={pageTitle} />;
      case "cta":
        return <Cta key={uid(16)} data={slice} theme={theme} />;
      case "clutch_reviews":
        return <ClutchReviews key={uid(16)} data={slice} />;
      case "contact":
        return <Contact key={uid(16)} data={slice} theme={theme} />;
      case "blog":
        return <Blog key={uid(16)} data={slice} />;
      case "pagelist":
        return <PageList key={uid(16)} data={slice} theme={theme} />;
      case "text":
        return <Text key={uid(16)} data={slice} />;
      case "new_text":
        return <NewText key={uid(16)} data={slice} />;
      case "team":
        return <Team key={uid(16)} data={slice} />;
      case "quote":
        return <Quote key={uid(16)} data={slice} theme={theme} />;
      case "values":
        return <OurValues key={uid(16)} data={slice} />;
      case "why_choose_us":
        return <WhyChooseUs key={uid(16)} data={slice} />;
      case "bullets_section":
        return <BulletsSection key={uid(16)} data={slice} />;
      case "logos_slider":
        return <LogosSlider key={uid(16)} data={slice} />;
      case "services_cards":
        return <ServicesCards key={uid(16)} data={slice} theme={theme} />;
      case "services_section":
        return <ServicesSection key={uid(16)} data={slice} />;
      case "extended_team":
        return <ExtendedTeamSection key={uid(16)} data={slice} />;
      case "full_width_image":
        return <FullWidthImage key={uid(16)} data={slice} />;
      case "images_slider":
        return <ImagesSlider key={uid(16)} data={slice} />;
      case "mobilesidebyside":
          return <MobileSideBySide key={uid(16)} data={slice} theme={theme} />;
      case "new_solution_carousel":
        return <NewSolutionCarousel key={uid(16)} data={slice} theme={theme} />;
      case "responsiveslider":
        return <ResponsiveSlider key={uid(16)} data={slice} theme={theme} />;
      case "dynamic_form":
        return <DynamicForm key={uid(16)} data={slice} theme={theme} />;
      case "calendly":
        return <CalendlySection key={uid(16)} data={slice} theme={theme} />;
      case "youtube_video":
        return <VideoSection key={uid(16)} data={slice} theme={theme} />;
      case "tally_embedded_form":
        return <TallyEmbeddedForm key={uid(16)} data={slice} theme={theme} />;
      default:
        return null;
    }
  });
};

export default SlicesGeneral;
