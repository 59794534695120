import { Link } from "gatsby";
import React from "react";
import { linkResolver } from "../utils/linkResolver";
import GatsbyImage from "./GatsbyImage";
import { Heading2 } from "./Heading2";
import clsx from "clsx";

export const BlogPost = post => {
  const { title, author, description, image, imageSVG, meta, postTheme } = post;

  return (
    <article className={clsx("md:relative", postTheme)}>
      <Link className="group flex gap-x-4 sm:flex-col" to={linkResolver(meta)}>
        {imageSVG?.gatsbyImageData ? (
          <div className="bg-primary order-2 sm:order-[0] flex-1 relative pb-[35%] rounded-md overflow-hidden sm:pb-1 h-0">
            <GatsbyImage
              className="absolute sm:static top-1/2 -translate-y-1/2 sm:translate-y-0 sm:top-auto"
              imgClassName="scale-100 !transition-transform !duration-300 !ease-in-out group-hover:scale-[1.02]"
              image={imageSVG}
            />
          </div>
        ) : (
          image?.gatsbyImageData && (
            <GatsbyImage
              className="order-2 sm:order-[0] flex-1 relative pb-[35%] bg-gray-200 rounded-md overflow-hidden sm:pb-1/5 h-0"
              imgClassName="scale-100 !transition-transform !duration-300 !ease-in-out group-hover:scale-[1.02]"
              image={image}
            />
          )
        )}
        <div className="sm:mt-8 flex-[1.6] flex flex-col">
          {author.text && (
            <p className="text-sm xs:uppercase sm:font-medium order-2 sm:order-[0] mt-2 sm:mt-0">
              by {author.text}
            </p>
          )}
          {title && (
            <Heading2 className="sm:mt-2 text-lg xs:text-2xl ml-0">
              {title.text}
            </Heading2>
          )}
          {description && (
            <p className="text-lg mt-2 hidden sm:block">{description.text}</p>
          )}
        </div>
      </Link>
    </article>
  );
};
