import React from "react";
import { uid } from "uid";
import { PrismicRichText } from "@prismicio/react";
import { linkResolver } from "../../utils/linkResolver";
import clsx from "clsx";
import Section from "../Section";
import { Paragraph } from "../Paragraph";
import { Heading2 } from "../Heading2";

const Text = props => {
  const {
    data: { primary },
  } = props;
  const { text_variant } = primary;

  switch (text_variant) {
    case "titleLeftBodyRight":
      return <TitleLeftBodyRight {...props} />;
    case "twoBodyColumns":
      return <TwoBodyColumns {...props} />;
    case "textCenter":
      return <TextCenter {...props} />;
    default:
      return <TextCenter {...props} />;
  }
};

const TextCenter = ({ data: { primary, items } }) => {
  const {
    text_title,
    text_rich_title,
    text_label,
    text_body_size,
    text_gray_background,
    slice_theme,
  } = primary;

  return (
    <Section
      className={clsx(
        "md:text-center",
        text_gray_background && "bg-grey-100",
        slice_theme
      )}
    >
      {text_label && <p className="text-pre mx-auto">{text_label.text}</p>}

      {text_rich_title ? (
        <PrismicRichText
          field={text_rich_title.richText}
          components={{
            heading2: ({ children }) => (
              <Heading2 className="max-w-5xl">{children}</Heading2>
            ),
            strong: ({ children }) => (
              <strong className="text-primary font-normal">{children}</strong>
            ),
          }}
        />
      ) : (
        <>{text_title && <Heading2>{text_title.text}</Heading2>}</>
      )}

      {items && (
        <div className={`grid max-w-4xl m-auto gap-10 sm:gap-20 md:gap-x-32`}>
          {items.map(({ text_body }) => {
            return (
              <div
                key={uid(16)}
                className={`content ${
                  text_body_size ? `text-${text_body_size}` : ""
                }`}
              >
                <PrismicRichText
                  field={text_body.richText}
                  linkResolver={linkResolver}
                  components={{
                    paragraph: Paragraph,
                  }}
                />
              </div>
            );
          })}
        </div>
      )}
    </Section>
  );
};

const TitleLeftBodyRight = ({ data: { primary, items } }) => {
  const {
    text_title,
    text_label,
    text_body_size,
    slice_theme,
    text_gray_background,
  } = primary;

  return (
    <Section
      className={clsx(slice_theme, text_gray_background && "bg-grey-100")}
    >
      <div className="text-left">
        {text_label && <p className="text-pre">{text_label.text}</p>}

        <div className="grid text-left sm:grid-cols-2 gap-10 sm:gap-20 md:gap-x-32">
          {text_title.text && (
            <div>
              <PrismicRichText
                field={text_title.richText}
                components={{
                  heading2: ({ children }) => (
                    <h2 className="max-w-3xl mx-auto text-2xl sm:text-3xl md:text-4.5xl font-display">
                      {children}
                    </h2>
                  ),
                  strong: ({ children }) => (
                    <strong className="text-primary font-normal">
                      {children}
                    </strong>
                  ),
                }}
              />
            </div>
          )}

          {items && (
            <div>
              {items.map(({ text_body }) => (
                <div
                  key={uid(16)}
                  className={clsx(
                    "content text-body",
                    text_body_size && `text-${text_body_size}`
                  )}
                >
                  <PrismicRichText
                    field={text_body.richText}
                    linkResolver={linkResolver}
                    components={{
                      paragraph: ({ children }) => (
                        <Paragraph className="md:!mt-0">{children}</Paragraph>
                      ),
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </Section>
  );
};

const TwoBodyColumns = ({ data: { primary, items } }) => {
  const {
    text_title,
    text_rich_title,
    text_label,
    text_body_size,
    slice_theme,
    text_gray_background,
  } = primary;

  return (
    <Section
      className={clsx(
        "text-left",
        slice_theme,
        text_gray_background && "bg-grey-100"
      )}
    >
      <div>
        {text_label.text && <p className="text-pre">{text_label.text}</p>}

        {text_rich_title ? (
          <PrismicRichText
            field={text_rich_title.richText}
            components={{
              heading2: ({ children }) => (
                <Heading2 className="ml-0 max-w-none md:max-w-[calc(50%-40px)] lg:max-w-[calc(50%-64px)]">
                  {children}
                </Heading2>
              ),
              strong: ({ children }) => (
                <strong className="text-primary font-normal">{children}</strong>
              ),
            }}
          />
        ) : (
          <>{text_title && <Heading2>{text_title.text}</Heading2>}</>
        )}

        {items && (
          <div className="grid text-left sm:grid-cols-2 gap-10 sm:gap-20 md:gap-x-32">
            {items.map(({ text_body }) => (
              <div
                key={uid(16)}
                className={clsx(text_body_size && `text-${text_body_size}`)}
              >
                <PrismicRichText
                  field={text_body.richText}
                  linkResolver={linkResolver}
                  components={{
                    heading2: ({ children }) => (
                      <Heading2 className="ml-0">{children}</Heading2>
                    ),
                    strong: ({ children }) => (
                      <strong className="text-primary font-normal">
                        {children}
                      </strong>
                    ),
                    paragraph: Paragraph,
                    list: ({ children }) => (
                      <ul className="px-8 md:px-0">{children}</ul>
                    ),
                    listItem: ({ children }) => (
                      <li className="relative mt-6 first:mt-0 pl-6 text-lg md:text-xl lg:text-2xl font-display font-normal text-left before:content-[''] before:absolute md:before:mt-[3px] before:w-3 before:h-3 before:top-[0.3125rem] before:left-0 before:bg-primary before:rounded-xxs">
                        {children}
                      </li>
                    ),
                  }}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </Section>
  );
};

export default Text;
