import { graphql, useStaticQuery } from "gatsby";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";
import React, { FC } from "react";
import GatsbyImage from "../GatsbyImage";
import clsx from "clsx";

const LogoItems = ({ logos }) => (
  <>
    {logos.map(({ image }) => (
      <li key={image.url} className="grow flex items-center justify-center">
        <GatsbyImage
          image={image}
          className="max-w-[140px] max-h-[62.5px] xl:max-w-[280px] xl:max-h-[125px] m-auto"
          aspectRatio={2.24}
        />
      </li>
    ))}
  </>
);

type LogosSliderProps = {
  data?: any;
  theme?: string;
};

export const LogosSlider: FC<LogosSliderProps> = ({ data, theme }) => {
  const logosData = useStaticQuery(logosQuery);
  const {
    data: { allPrismicLogos },
  } = useMergePrismicPreviewData(logosData);
  const { logos } = allPrismicLogos?.edges[0].node.data;

  const slice_theme = data?.primary?.slice_theme || theme;

  return (
    <div
      className={clsx(
        "h-[67px] xl:h-[125px] bg-primary overflow-hidden",
        slice_theme
      )}
    >
      <ul className="flex items-center list-none m-0 h-full transform-gpu animate-logos-slider w-[calc(2*var(--logos-slider-inner-width))]">
        <LogoItems logos={logos} />
        <LogoItems logos={logos} />
      </ul>
    </div>
  );
};

const logosQuery = graphql`
  query LogosQuery {
    allPrismicLogos {
      edges {
        node {
          data {
            logos_title {
              text
            }
            logos {
              image {
                url
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;
