import React, { FC } from "react";
import { PrismicRichText } from "@prismicio/react";
import { linkResolver } from "../../utils/linkResolver";

import Section from "../Section";
import { SliceProps } from "../../types";
import clsx from "clsx";

const ClientQuote: FC<SliceProps> = ({ data: { primary } }) => {
  const {
    clientquote_title,
    clientquote_body,
    clientquote_name,
    clientquote_quote,
    slice_gray_background,
    slice_inverted_colours,
    slice_theme,
  } = primary;

  return (
    <Section
      className={clsx(
        "t-page",
        slice_gray_background && "bg-gray-100",
        slice_inverted_colours && "bg-primary text-white",
        slice_theme
      )}
    >
      <div className="relative sm:pl-12 xl:flex">
        <blockquote className="flex flex-col mb-10 sm:mb-16 xl:w-2/3 xl:pr-32">
          <div className="relative order-3">
            <div className="flex mb-6 order-2 sm:absolute left-0 sm:-ml-12 sm:pt-3 lg:pt-2">
              <div
                className={clsx(
                  "w-2 h-2 rounded-sm mr-2",
                  slice_inverted_colours ? "bg-white" : "bg-primary"
                )}
              ></div>
              <div
                className={clsx(
                  "w-2 h-2 rounded-sm mr-2",
                  slice_inverted_colours ? "bg-white" : "bg-primary"
                )}
              ></div>
            </div>
            {clientquote_quote.text && (
              <div className="text-3xl">{clientquote_quote.text}</div>
            )}
          </div>
          {clientquote_name.text && (
            <footer className="order-1 mb-5 text-lg leading-tight font-bold text-lgFX">
              {clientquote_name.text}
            </footer>
          )}
        </blockquote>

        <div className="xl:w-1/3">
          {clientquote_title.text && (
            <h2 className="mb-3 text-lg leading-tight font-bold text-primary">
              {clientquote_title.text}
            </h2>
          )}
          {clientquote_body.richText.length > 0 && (
            <div className="content max-w-lg">
              <PrismicRichText
                field={clientquote_body.richText}
                linkResolver={linkResolver}
              />
            </div>
          )}
        </div>
      </div>
    </Section>
  );
};

export default ClientQuote;
