import { Script } from "gatsby";
import React, { useEffect, useState } from "react";

import Section from "../Section";

declare global {
  interface Window {
    CLUTCHCO: any;
  }
}

export const ClutchReviews = () => {
  const [clutchCoInitialised, setClutchCoInitialised] = useState(false);

  useEffect(() => {
    /* eslint-disable */
    const i = setInterval(() => {
      if (window?.CLUTCHCO && !clutchCoInitialised) {
        setClutchCoInitialised(true);
        window?.CLUTCHCO.Init();
      }
    }, 500);
    return () => clearInterval(i);
    /* eslint-enable */
  }, [clutchCoInitialised]);

  return (
    <Section
      fullWidth
      style={{ backgroundColor: "#fafafa" }}
      paddingY="sm:pt-12"
    >
      <Script
        type="text/javascript"
        src="https://widget.clutch.co/static/js/widget.js"
      />
      <div className="grid place-items-center h-full">
        <div
          className="clutch-widget w-full"
          data-nofollow="true"
          data-url="https://widget.clutch.co"
          data-widget-type="12"
          data-height="420"
          data-clutchcompany-id="1472771"
          data-primary-color="#5E2394"
          style={{ maxWidth: "1240px" }}
        />
      </div>
    </Section>
  );
};
