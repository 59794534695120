import React from "react";
import { graphql } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import { linkResolver } from "../utils/linkResolver";
import { getSeo, getSliceData } from "../utils/helpers";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

import Layout from "../components/Layout";
import Section from "../components/Section";
import GatsbyImage from "../components/GatsbyImage";

import SlicesWork from "../components/SlicesWork";
import Seo from "../components/SEO/SEO";
import { WorkHero } from "../components/work/WorkHero";

const Work = ({ data }) => {
  const work = data.prismicWork.data;
  if (!work) return <div>loading...</div>;

  const {
    title,
    description,
    image,
    image_logo,
    has_image_overlay,
    page_theme,
  } = work;
  const theme = page_theme || "theme-purple-400";

  return (
    <Layout theme={theme} transparentBG={image?.url ? true : false}>
      <Seo
        title={work.title.text}
        desc={work.description.text}
        category={data.prismicWork.type}
        visitorValue="high"
        prismicSeo={getSeo(work?.body)}
        pageColors={getSliceData(work?.body, "theme_slice")}
      />
      <WorkHero bgImage={image?.url} hasImageOverlay={has_image_overlay} />
      <Section
        paddingY="py-0"
        className="t-page max-w-8xl m-auto xl:-mt-40 xl:bg-white xl:rounded-t-md"
      >
        <div className="m-auto pt-12 xl:-mt-32 xl:pt-16 xl:pb-0">
          <div className="md:grid md:grid-cols-2 bg-white">
            <div>
              {title.text && (
                <h2 className="text-h2 max-w-xl mb-6">{title.text}</h2>
              )}

              {description?.richText?.length > 0 && (
                <div className="content max-w-lg">
                  <PrismicRichText
                    field={description.richText}
                    linkResolver={linkResolver}
                  />
                </div>
              )}
            </div>

            {image_logo && (
              <div className="py-16 md:py-0">
                <div className="relative">
                  <GatsbyImage
                    image={image_logo}
                    aspectRatio={1}
                    className="max-w-xss xl:max-w-xs m-auto xl:mt-4"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Section>
      <SlicesWork allSlices={work.body} theme={theme} />
    </Layout>
  );
};

export const query = graphql`
  query WorkQuery($uid: String!) {
    prismicWork(uid: { eq: $uid }) {
      _previewable
      type
      uid
      data {
        page_theme
        title {
          text
          richText
        }
        description {
          text
          richText
        }
        image {
          url
          gatsbyImageData
        }

        has_image_overlay
        image_logo {
          url
          gatsbyImageData
        }
        body {
          ... on PrismicWorkDataBodySeo {
            slice_type
            primary {
              seo_title
              seo_description
              seo_category
              seo_visitor_value
              seo_script {
                text
              }
              seo_image {
                url
              }
            }
          }
          ... on PrismicWorkDataBodyThemeSlice {
            id
            slice_type
            primary {
              primary_color
              secondary_color
              secondary_color_hover
              tertiary_color
            }
          }
          ... on PrismicWorkDataBodyPagelist {
            id
            slice_type
            primary {
              page_list_type
              page_list_title {
                text
                richText
              }
              page_list_body {
                text
                richText
              }
            }
            items {
              page_list_item_title {
                text
                richText
              }
              page_list_item_label
              page_list_item_link {
                uid
                type
                link_type
                document {
                  ... on PrismicWork {
                    data {
                      title {
                        text
                        richText
                      }
                      description {
                        text
                        richText
                      }
                      image {
                        gatsbyImageData
                      }
                    }
                  }
                  ... on PrismicNewWork {
                    data {
                      title {
                        text
                        richText
                      }
                      description {
                        text
                        richText
                      }
                      image: featured_image {
                        gatsbyImageData
                      }
                      hero_background_image {
                        gatsbyImageData
                        large: gatsbyImageData
                      }
                      background_as_thumbnail
                    }
                  }
                  ... on PrismicService {
                    data {
                      title {
                        text
                        richText
                      }
                      description {
                        text
                        richText
                      }
                    }
                  }
                  ... on PrismicCareer {
                    data {
                      title {
                        text
                        richText
                      }
                      description {
                        text
                        richText
                      }
                    }
                  }
                  ... on PrismicPost {
                    data {
                      title {
                        text
                        richText
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicWorkDataBodyCta {
            id
            slice_type
            primary {
              cta_title {
                text
              }
              cta_body {
                text
                richText
              }
              cta_text {
                text
              }
              cta_link {
                url
                link_type
                target
                slug
                uid
              }
              slice_theme
            }
          }
          ... on PrismicWorkDataBodyNewText {
            id
            slice_type
            primary {
              new_text_label: text_label
              new_text_title: text_title {
                text
                richText
              }
              text_centered
              text_layout
              text_remove_padding_bottom
              text_span_title
              slice_gray_background
              slice_inverted_colours
              slice_theme
            }
            items {
              column_body {
                text
                richText
              }
            }
          }
          ... on PrismicWorkDataBodyQuote {
            id
            slice_type
            primary {
              quote_title {
                text
                richText
              }
              quote_name {
                text
                richText
              }
              quote_body {
                text
                richText
              }
              quote_image {
                alt
                gatsbyImageData
              }
            }
          }
          ... on PrismicWorkDataBodyTextandsvg {
            id
            slice_type
            primary {
              textandsvg_label {
                text
                richText
              }
              textandsvg_title {
                text
                richText
              }
              textandsvg_body {
                text
                richText
              }
              textandsvg_svg {
                text
              }
            }
          }
          ... on PrismicWorkDataBodyOutcome {
            slice_type
            primary {
              outcome_image_top {
                gatsbyImageData
              }
              outcome_image_bottom {
                gatsbyImageData
              }
            }
            items {
              outcome_item_text {
                text
                richText
              }
            }
          }
          ... on PrismicWorkDataBodyChallenges {
            slice_type
            primary {
              challenges_title {
                text
                richText
              }
              challenges_body {
                text
                richText
              }
            }
            items {
              challenges_item_title {
                text
                richText
              }
              challenges_item_body {
                text
                richText
              }
              challenges_item_image {
                gatsbyImageData
              }
            }
          }
          ... on PrismicWorkDataBodyClientquote {
            slice_type
            primary {
              clientquote_title {
                text
                richText
              }
              clientquote_body {
                text
                richText
              }
              clientquote_name {
                text
                richText
              }
              clientquote_quote {
                text
                richText
              }
              slice_gray_background
              slice_inverted_colours
              slice_theme
            }
          }
          ... on PrismicWorkDataBodyBeforeafter {
            slice_type
            primary {
              beforeafter_title {
                text
                richText
              }
              beforeafter_body {
                text
                richText
              }
              beforeafter_image_before {
                url
                gatsbyImageData
              }

              beforeafter_image_after {
                url
                gatsbyImageData
              }
            }
          }
          ... on PrismicWorkDataBodyResponsiveslider {
            slice_type
            primary {
              responsiveslider_title {
                text
                richText
              }
              responsiveslider_body {
                text
                richText
              }
            }
            items {
              responsiveslider_item_image {
                gatsbyImageData
              }
            }
          }
          ... on PrismicWorkDataBodyDisccolor {
            slice_type
            primary {
              disccolor_title {
                text
                richText
              }
              disccolor_body {
                text
                richText
              }
            }
          }
          ... on PrismicWorkDataBodySolotioncarousel {
            slice_type
            primary {
              solotioncarousel_title {
                text
                richText
              }
              solotioncarousel_theme
            }
            items {
              solotioncarousel_body {
                text
                richText
              }
              solotioncarousel_item_image {
                gatsbyImageData
              }
            }
          }
          ... on PrismicWorkDataBodyMobilesidebyside {
            slice_type
            primary {
              mobilesidebyside_title {
                text
                richText
              }
              mobilesidebyside_body {
                text
                richText
              }
              mobilesidebyside_has_text_top
            }
            items {
              mobilesidebyside_item_image {
                gatsbyImageData
              }
            }
          }
          ... on PrismicWorkDataBodyFullwidthimage {
            slice_type
            primary {
              fullwidthimage_image {
                gatsbyImageData
              }
              fullwidthimage_label {
                text
                richText
              }
              fullwidthimage_title {
                text
                richText
              }
              fullwidthimage_title_max_width
              fullwidthimage_image_bottom
              fullwidthimage_body {
                text
                richText
              }
              slice_gray_background
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(Work);
