module.exports = {
  linkResolver(linkMeta, parent, path) {

    if(linkMeta?.link_type === "Web") return linkMeta?.url;

    if (linkMeta?.type === "new_page") return `/${linkMeta?.uid}`

    if (linkMeta?.type === "dynamic_page") {
      if(path) return path;
      if(parent) return `/${parent}/${linkMeta?.uid}`;
      return `/${linkMeta?.uid}`;
    }

    // pages
    if (linkMeta?.type === "post") return `/knowledge/${linkMeta?.uid}`
    if (linkMeta?.type === "work" || linkMeta?.type === "new_work") return `/work/${linkMeta?.uid}`
    if (linkMeta?.type === "notfound") return `/404/${linkMeta?.uid}`

    return "/"
  },
}
