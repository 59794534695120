import React from "react";
import Email from "./Email";
import Twitter from "./Twitter";
import Brand from "./Brand";
import Facebook from "./Facebook";
import Instagram from "./Instagram";
import Linkedin from "./Linkedin";
import HamburgerOpen from "./hamburger-open";
import HamburgerClosed from "./hamburger-closed";
import { ChevronIcon } from "./Chevron";
import { CloseIcon } from "./CloseIcon";
import { Lupe } from "./Lupe";

const Icon = props => {
  switch (props.name) {
    case "brand":
      return <Brand {...props} />;
    case "email":
      return <Email {...props} />;
    case "facebook":
      return <Facebook {...props} />;
    case "instagram":
      return <Instagram {...props} />;
    case "linkedin":
      return <Linkedin {...props} />;
    case "twitter":
      return <Twitter {...props} />;
    case "hamburger-closed":
      return <HamburgerClosed {...props} />;
    case "hamburger-open":
      return <HamburgerOpen {...props} />;
    case "chevron":
      return <ChevronIcon {...props} />;
    case "close":
      return <CloseIcon {...props} />;
    case "lupe":
      return <Lupe {...props} />;
    default:
      return null;
  }
};

export default Icon;
