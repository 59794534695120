import clsx from "clsx";
import React, { useState } from "react";

const inputCls =
  "w-full rounded-default outline-none transition duration-300 ease-in-out border-[1px] border-gray-400 placeholder:text-gray-300 focus:border-gray-500 py-[11px] px-3.5 leading-4";

const Input = allProps => {
  const { type, className, ...props } = allProps;
  const [touched, setTouched] = useState(false);

  const onFocusOut = () => {
    setTouched(true);
  };

  return (
    <>
      {type === "textarea" ? (
        <textarea
          {...props}
          className={clsx(
            inputCls,
            touched && "invalid:border-themeRed-300",
            className
          )}
          onBlur={touched ? null : onFocusOut}
        />
      ) : (
        <input
          type={type}
          {...props}
          className={clsx(
            inputCls,
            touched && "invalid:border-themeRed-300",
            className
          )}
          onBlur={touched ? null : onFocusOut}
        />
      )}
    </>
  );
};

const Contact = ({ theme }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [tel, setTel] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");

  const success = "success";
  const error = "error";
  const [status, setStatus] = useState("");

  const encode = data => {
    const formData = new FormData();
    Object.keys(data).forEach(k => {
      formData.append(k, data[k]);
    });
    return formData;
  };

  const handleSubmit = event => {
    const data = { "form-name": "contact", name, email, tel, company, message };

    fetch("/", {
      method: "POST",
      // headers: { "Content-Type": 'multipart/form-data; boundary=random' },
      body: encode(data),
    })
      .then(() => setStatus(success))
      .catch(() => setStatus(error));

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "FormSubmit",
      eventLabel: "contact",
    });

    event.preventDefault();
  };

  const handleChange = event => {
    const { name, value } = event.target;

    switch (name) {
      case "name":
        return setName(value);
      case "email":
        return setEmail(value);
      case "tel":
        return setTel(value);
      case "company":
        return setCompany(value);
      case "message":
        return setMessage(value);
      default:
        return;
    }
  };

  return (
    <>
      <form
        name="contact"
        // method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={handleSubmit}
        action="/thank-you/"
        className="xl:max-w-xl t-blue--red"
      >
        {/* eslint-disable */}
        <input type="hidden" name="bot-field" />
        <input type="hidden" name="form-name" value="contact" />

        {success !== status ? (
          <section>
            <div className="grid grid-cols-1 gap-y-4 text-sm">
              <div>
                <Input
                  type="text"
                  name="name"
                  value={name}
                  onChange={handleChange}
                  placeholder="Name *"
                  id="name"
                  required
                />
              </div>

              <div>
                <Input
                  type="email"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  placeholder="Email *"
                  id="email"
                  required
                />
              </div>

              <div>
                <Input
                  type="tel"
                  name="tel"
                  value={tel}
                  onChange={handleChange}
                  placeholder="Phone *"
                  id="tel"
                  required
                />
              </div>

              <div>
                <Input
                  type="text"
                  name="company"
                  value={company}
                  onChange={handleChange}
                  placeholder="Company *"
                  id="text"
                  required
                />
              </div>

              <div>
                <Input
                  type="textarea"
                  name="message"
                  placeholder="Message *"
                  value={message}
                  onChange={handleChange}
                  id="message"
                  required
                  className="block h-24"
                />
              </div>
            </div>

            <div className="py-6">
              <input
                type="submit"
                value="Let's talk"
                className="inline-block py-3 xs:py-3.5 px-8 leading-4 xs:leading-6 md:leading-7 text-white text-sm xs:text-base md:text-lg font-semibold rounded-default cursor-pointer duration-300 ease-in-out bg-secondary hover:bg-secondary-hover tracking-wide"
              />
            </div>

            {status === error && (
              <section>
                <div>
                  <p className="text-red-600 text-sm">
                    Something went wrong, please email us at{" "}
                    <a href="mailto:sales@thisisstoked.com">
                      sales@thisisstoked.com
                    </a>
                    .
                  </p>
                </div>
              </section>
            )}
          </section>
        ) : (
          <section className="py-20 md:py-0">
            <h2 className="text-h2 mb-6">
              Thanks for <br />
              getting in touch!{" "}
            </h2>
            <p className="mb-5">
              We appreciate you contacting us. One of our colleagues will get
              back in touch with you soon!
            </p>
            <p>Have a great day!</p>
          </section>
        )}
      </form>
    </>
  );
};

export default Contact;
