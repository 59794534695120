import React from "react";

const SVG = ({
  style = {},
  width = "1.75rem",
  height = "1.75rem",
  className = "",
  viewBox = "0 0 3619.61 3619.61",
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    className={`svg-icon fill-current transition duration-300 ease-in-out ${
      className || ""
    }`}
    clipRule="evenodd"
    fillRule="evenodd"
    imageRendering="optimizeQuality"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="m3203.74 2.7h-2793.26c-227.29 0-413.17 185.89-413.17 413.17v2793.26c0 227.28 185.89 413.17 413.17 413.17h2793.26c227.28 0 413.17-185.89 413.17-413.17v-2793.26c0-227.29-185.89-413.17-413.17-413.17z" />
    <g fill="#fff" fillRule="nonzero">
      <path d="m1019.01 776.8c137.56 0 249.24 111.72 249.24 249.36 0 137.68-111.68 249.4-249.24 249.4-138.11 0-249.48-111.72-249.48-249.4 0-137.64 111.37-249.36 249.48-249.36zm-215.34 687.8h430.45v1383.61h-430.45z" />
      <path d="m1503.89 1464.59h412.2v189.15h5.89c57.34-108.77 197.65-223.48 406.86-223.48 435.4 0 515.86 286.49 515.86 659.13v758.81h-429.98v-672.85c0-160.45-2.76-366.87-223.44-366.87-223.76 0-257.9 174.88-257.9 355.38v684.33h-429.5v-1383.61z" />
    </g>
  </svg>
);

export default SVG;
