import React from "react";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { getSeo } from "../utils/helpers";
import Layout from "../components/Layout";
import Seo from "../components/SEO/SEO";
import { Hero } from "../components/NewHero";
import SlicesBlog from "../components/SlicesBlog";
import { useTrail } from "../hooks/useTrail";
import GatsbyImage from "../components/GatsbyImage";

const Post = ({ data, location: { href } }) => {
  const post = data.prismicPost.data;

  if (!post) return <div>loading...</div>;

  const theme = post.page_theme || "theme-purple-400";

  const TrailAnimationWrapper = useTrail();

  return (
    <Layout theme={theme}>
      <Seo
        title={post.title}
        desc={post.description}
        category={post?._meta?.type}
        prismicSeo={getSeo(post?.body)}
      />
      <article>
        <TrailAnimationWrapper className="" Tag="div" theme={theme}>
          <Hero
            className="sm:pb-8 xl:pt-16"
            title={post.title}
            author={post.author}
            staticImage={post.svg_image}
            alignImageBottom={false}
          />
        </TrailAnimationWrapper>
        <div id="content">
          <p className="px-5 sm:px-10 font-display font-medium max-w-3xl mx-auto text-2xl mt-6 sm:mt-12">
            {post.description.text}
          </p>
          <SlicesBlog allSlices={post.body} />
        </div>
      </article>
    </Layout>
  );
};

export const query = graphql`
  query PostQuery($uid: String) {
    prismicPost(uid: { eq: $uid }) {
      _previewable
      type
      data {
        page_theme
        title {
          text
        }
        description {
          text
          richText
        }
        date
        author {
          text
        }
        image {
          gatsbyImageData
        }
        svg_image {
          gatsbyImageData
        }
        body {
          ... on PrismicPostDataBodySeo {
            slice_type
            primary {
              seo_title
              seo_description
              seo_category
              seo_visitor_value
              seo_script {
                text
              }
              seo_image {
                url
              }
            }
          }
          ... on PrismicPostDataBodyContent {
            slice_type
            primary {
              post_content {
                richText
              }
            }
          }
          ... on PrismicPostDataBodyEmbed {
            slice_type
            primary {
              code {
                text
                richText
              }
            }
          }
          ... on PrismicPostDataBodyImage {
            slice_type
            primary {
              post_image {
                gatsbyImageData
              }
              post_image_caption {
                text
                richText
              }
            }
          }
          ... on PrismicPostDataBodyYoutube {
            slice_type
            primary {
              url {
                text
              }
              start {
                text
              }
            }
          }
          ... on PrismicPostDataBodyCta {
            id
            slice_type
            primary {
              cta_title {
                text
              }
              cta_body {
                text
                richText
              }
              cta_text {
                text
              }
              cta_link {
                url
                type
                link_type
                target
                slug
                uid
              }
              slice_theme
            }
          }
          ... on PrismicPostDataBodyRelatedPosts {
            id
            items {
              related_post {
                document {
                  ... on PrismicPost {
                    id
                    uid
                    type
                    data {
                      author {
                        richText
                        text
                      }
                      description {
                        richText
                        text
                      }
                      title {
                        richText
                        text
                      }
                      image {
                        gatsbyImageData
                      }
                    }
                  }
                }
              }
            }
            primary {
              section_title {
                text
                richText
              }
            }
            slice_type
          }
          ... on PrismicPostDataBodyCalendly {
            id
            slice_type
            primary {
              section_title {
                richText
              }
              subline
              main_content {
                richText
              }
              calendly_url
              slice_theme
              slice_layout
            }
          }
          ... on PrismicPostDataBodyYoutubeVideo {
            id
            slice_type
            primary {
              section_title {
                richText
              }
              subline
              youtube_video_url
            }
          }
          ... on PrismicPostDataBodyTallyEmbeddedForm {
            id
            slice_type
            primary {
              section_title {
                richText
              }
              subline
              main_content {
                richText
              }
              tally_form_id
              popup
              popup_button_text
              slice_theme
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(Post);
