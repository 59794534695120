import React, {
  useState,
  useEffect,
  createContext,
  FC,
  useRef,
  useCallback,
  ReactNode,
} from "react";

type IntersectionObserverContext = {
  addTarget: (
    target: HTMLElement,
    onEnterViewCallback: () => void,
    onLeaveView?: () => void
  ) => void;
  initialized: boolean;
};

type IntersectionObserverContextProviderProps = {
  children: ReactNode;
};

type ObserverTarget = {
  onEnterViewCallback: () => void;
  onLeaveViewCallback?: () => void;
};

type ObserverTargets = Record<string, ObserverTarget>;

export const IntersectionObserverContext =
  createContext<IntersectionObserverContext>({
    addTarget: () => {},
    initialized: false,
  });

export const IntersectionObserverContextProvider: FC<
  IntersectionObserverContextProviderProps
> = ({ children }) => {
  const [initialized, setInitialized] = useState(false);
  const [observerTargets, setObserverTargets] = useState<ObserverTargets>();
  const observerRef = useRef(null);

  //This is someway necessary in order to access state from the intersection observer callback :S
  const observerTargetsRef = useRef<ObserverTargets>();
  observerTargetsRef.current = observerTargets;

  const addTarget = (
    target: HTMLElement,
    onEnterViewCallback: () => void,
    onLeaveViewCallback?: () => void
  ) => {
    setObserverTargets(previousValue => {
      const newValue: ObserverTargets = { ...previousValue };
      newValue[target.dataset.id as string] = {
        onEnterViewCallback,
        onLeaveViewCallback,
      };

      return newValue;
    });

    (observerRef.current! as IntersectionObserver).observe(target);
  };

  const observerCallback: IntersectionObserverCallback = useCallback(
    entries => {
      entries.forEach(entry => {
        if (!observerTargetsRef.current) return;

        const observerTarget = (observerTargetsRef.current as ObserverTargets)[
          (entry.target as HTMLElement).dataset.id as string
        ];

        if (
          entry.isIntersecting &&
          observerTarget &&
          observerTarget.onEnterViewCallback
        ) {
          observerTarget.onEnterViewCallback();
        } else if (observerTarget && observerTarget.onLeaveViewCallback) {
          observerTarget.onLeaveViewCallback();
        }
      });
    },
    []
  );

  useEffect(() => {
    (observerRef.current as unknown) = new IntersectionObserver(
      observerCallback,
      { threshold: [0.1] }
    );
    setInitialized(true);
  }, [observerCallback]);

  return (
    <IntersectionObserverContext.Provider
      value={{
        addTarget,
        initialized,
      }}
    >
      {children}
    </IntersectionObserverContext.Provider>
  );
};
