import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";
import { PrismicRichText } from "@prismicio/react";
import { Paragraph } from "./Paragraph";
import { linkResolver } from "../utils/linkResolver";
import Icon from "../icon";

const SectionContent = ({ title, description, linked = false }) => (
  <>
    <h3 className="text-lg sm:text-2xl font-display text-primary relative">
      {linked && (
        <Icon
          name="chevron"
          className="absolute w-[8px] md:w-[9px] lg:w-[11px] right-0 top-1/2 -translate-y-1/2 -translate-x-[20px] transition-transform transition-duration-150 group-hover:translate-x-0"
        />
      )}
      {title.text}
    </h3>
    <PrismicRichText
      field={description.richText}
      linkResolver={linkResolver}
      components={{
        paragraph: ({ children }) => (
          <Paragraph className="text-sm sm:text-lg md:!mt-2">
            {children}
          </Paragraph>
        ),
      }}
    />
  </>
);

export const ServiceCategorySection = ({ categoryUid }) => {
  const servicePagesData = useStaticQuery(allServicePagesQuery);

  const {
    data: { allPrismicServicePage },
  } = useMergePrismicPreviewData(servicePagesData);

  const { edges: servicePages } = allPrismicServicePage;

  const currentCategoryServices = servicePages.filter(
    servicePage => servicePage.node.data.service_category.uid === categoryUid
  );

  return (
    <ul>
      {currentCategoryServices.map(
        ({ node: { data: servicePage, uid: slug } }) => {
          const { title, description, link_on_services_page } = servicePage;

          return (
            <li key={slug} className="mt-8 first:mt-0">
              {link_on_services_page ? (
                <Link
                  to={`/services/${categoryUid}/${slug}`}
                  className="block group"
                >
                  <SectionContent
                    title={title}
                    description={description}
                    linked
                  />
                </Link>
              ) : (
                <SectionContent title={title} description={description} />
              )}
            </li>
          );
        }
      )}
    </ul>
  );
};

const allServicePagesQuery = graphql`
  query ServiesByCategoryQuery {
    allPrismicServicePage(sort: { data: { service_category: { slug: ASC } } }) {
      edges {
        node {
          uid
          data {
            service_category {
              uid
            }
            link_on_services_page
            title {
              text
              richText
            }
            description {
              text
              richText
            }
          }
        }
      }
    }
  }
`;
