import React from "react";

const Embed = ({
  data: {
    primary: { code },
  },
}) => {
  return (
    <>
      {code.text && (
        <div className="max-w-2xl py-6 sm:py-10 mx-auto">
          <div
            dangerouslySetInnerHTML={{
              __html: code.text,
            }}
          />
        </div>
      )}
    </>
  );
};

export default Embed;
