import { PrismicRichText } from "@prismicio/react";
import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { uid } from "uid";
import { Slice, RichTextField } from "@prismicio/types";
import clsx from "clsx";
import { IntersectionObserverContext } from "../../contexts/IntersectionObserverContext";

type BulletsSectionProps = {
  data: Slice<
    "bullets_section",
    {
      section_title: {
        richText: RichTextField;
      };
      section_content: {
        richText: RichTextField;
      };
      slice_theme?: string;
    },
    {
      bullet_text: string;
    }
  >;
};

const BulletItem = ({ children, className }) => (
  <li
    className={clsx(
      "flex-1 relative pl-5 sm:pt-5 sm:pl-0 text-lg md:text-xl lg:text-2xl font-display font-normal text-left before:content-[''] before:absolute before:w-3 before:h-3 before:top-[0.3125rem] sm:before:top-0 before:left-0 before:bg-primary sm:before:bg-grey-200 before:rounded-xxs",
      className
    )}
  >
    {children}
  </li>
);

export const BulletsSection: FC<BulletsSectionProps> = ({ data }) => {
  const { primary, items } = data;
  const { section_title, section_content, slice_theme } = primary;
  const baseRef = useRef(null);
  const { initialized, addTarget } = useContext(IntersectionObserverContext);
  const [onView, setOnView] = useState(false);
  const baseId = useRef(uid(16));

  const onEnterView = () => {
    setOnView(true);
  };

  const onLeaveView = () => {
    setOnView(false);
  };

  const itemsCount = items.length;

  useEffect(() => {
    if (initialized && baseRef.current) {
      addTarget(baseRef.current, onEnterView, onLeaveView);
    }
  }, [initialized]);

  return (
    <div
      className={clsx(
        "sm:text-center py-8 sm:py-16 md:py-24 px-5 sm:px-12 mx-auto",
        slice_theme
      )}
    >
      {section_title && (
        <PrismicRichText
          field={section_title.richText}
          components={{
            heading2: ({ children }) => (
              <h2 className="max-w-3xl mx-auto text-2xl sm:text-3xl md:text-4.5xl font-display">
                {children}
              </h2>
            ),
            strong: ({ children }) => (
              <strong className="text-primary font-normal">{children}</strong>
            ),
          }}
        />
      )}
      <PrismicRichText
        field={section_content.richText}
        components={{
          paragraph: ({ children }) => (
            <p className="content text-sm sm:text-base md:text-lg mt-2 sm:mt-5 md:mt-8 max-w-screen-lg mx-auto">
              {children}
            </p>
          ),
        }}
      />
      <div
        ref={baseRef}
        data-id={baseId.current}
        className={clsx(
          `[--gap:80px] [--bullet-width:12px] [--bullet-height:var(--bullet-width)] [--animation-time:3s] ${
            itemsCount == 4
              ? "[--total-gap:var(--gap)*3] [--items-count:4]"
              : "[--total-gap:var(--gap)*2] [--items-count:3]"
          } [--bullet-2-position:calc((100%-(var(--total-gap)))/var(--items-count)+var(--gap))] [--bullet-3-position:calc((100%-(var(--total-gap)))/var(--items-count)*2+(var(--gap)*2))] [--bullet-4-position:calc((100%-(var(--total-gap)))/var(--items-count)*3+(var(--gap)*3))] [--border-radius:1.3px] relative group ${
            itemsCount == 4 ? "max-w-7xl" : "max-w-screen-lg"
          } mx-auto`,
          onView ? "on-view" : null
        )}
      >
        <div
          className={clsx(
            "absolute w-full top-0 -z-1",
            `before:absolute before:w-[calc(var(--bullet-2-position)+var(--bullet-width))] before:h-[var(--bullet-height)] before:rounded-[var(--border-radius)] before:left-0 before:top-0 before:origin-left before:scale-x-0 before:bg-secondary before:opacity-50 ${
              itemsCount == 4
                ? "sm:group-[.on-view]:before:animate-four-bullets-trail-1"
                : "sm:group-[.on-view]:before:animate-three-bullets-trail-1"
            }`,
            `after:absolute after:w-[calc(var(--bullet-2-position)+var(--bullet-width))] after:h-[var(--bullet-height)] after:rounded-[var(--border-radius)] after:left-0 after:top-0 after:origin-left after:scale-x-0 after:bg-secondary after:opacity-80 ${
              itemsCount == 4
                ? "sm:group-[.on-view]:after:animate-four-bullets-trail-2"
                : "sm:group-[.on-view]:after:animate-three-bullets-trail-2"
            }`
          )}
        />
        <ul
          className={clsx(
            "relative flex flex-col sm:flex-row justify-between gap-5 md:gap-20 px-8 sm:px-0 mt-2 sm:mt-12 md:mt-16",
            `before:hidden sm:before:block before:absolute before:w-[var(--bullet-width)] before:h-[var(--bullet-height)] before:rounded-[var(--border-radius)] before:left-0 before:top-0 before:z-[2] before:bg-primary ${
              itemsCount == 4
                ? "sm:group-[.on-view]:before:animate-four-bullets-animation"
                : "sm:group-[.on-view]:before:animate-three-bullets-animation"
            }`
          )}
        >
          {items.map(({ bullet_text }, index) => {
            let animation = "sm:group-[.on-view]:before:animate-bullets-color";
            let delay = "sm:group-[.on-view]:before:animation-delay-none";
            if (index == 1)
              delay = `${
                itemsCount == 4
                  ? "sm:group-[.on-view]:before:animation-delay-bullets-animation-2/3"
                  : "sm:group-[.on-view]:before:animation-delay-bullets-animation-1/5"
              }`;
            if (index == 2)
              delay = `${
                itemsCount == 4
                  ? "sm:group-[.on-view]:before:animation-delay-bullets-animation-1/2"
                  : "sm:group-[.on-view]:before:animation-delay-bullets-animation-1/1"
              }`;
            if (index == 3)
              delay =
                "sm:group-[.on-view]:before:animation-delay-bullets-animation-1/1";

            return (
              <BulletItem key={uid(16)} className={clsx(animation, delay)}>
                {bullet_text}
              </BulletItem>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
