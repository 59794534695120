import React, { FC, Fragment } from "react";
import { uid } from "uid";
import GatsbyImage from "../GatsbyImage";
import { SliceProps } from "../../types";

const Outcome: FC<SliceProps> = ({ data: { primary, items } }) => {
  const { outcome_image_top, outcome_image_bottom } = primary;

  return (
    <section className="bg-white">
      <div className="container">
        <div className="max-w-8xl m-auto">
          {outcome_image_top?.gatsbyImageData && (
            <div className="-ml-5 sm:-ml-10 md:hidden">
              <GatsbyImage image={outcome_image_top} />
            </div>
          )}
          {items && (
            <div className="t-page text-primary grid grid-cols-2 gap-8 py-12 lg:grid-cols-4 md:gap-16 md:pt-20 md:pb-16 xl:px-16">
              {items.map(item => {
                const { outcome_item_text } = item;

                return (
                  <Fragment key={uid(16)}>
                    {outcome_item_text.text && (
                      <div>
                        <div className="w-3 h-3 mr-auto mb-5 rounded-sm bg-primary"></div>
                        <div className="max-w-xss lg:max-w-xs text-lg font-bold text-left">
                          {outcome_item_text.text}
                        </div>
                      </div>
                    )}
                  </Fragment>
                );
              })}
            </div>
          )}
          {outcome_image_bottom?.gatsbyImageData && (
            <div className="hidden md:block xl:px-16">
              <GatsbyImage image={outcome_image_bottom} />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Outcome;
