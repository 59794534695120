import React, { FC } from "react";
import { GatsbyImage as Img, IGatsbyImageData } from "gatsby-plugin-image";

type GatsbyImageProps = {
  image: any;
  className?: string;
  imgClassName?: string;
  aspectRatio?: number;
};

const GatsbyImage: FC<GatsbyImageProps> = ({
  image,
  className = "",
  imgClassName = "",
  aspectRatio = "",
}) => (
  <>
    {image.gatsbyImageData ? (
      <Img
        image={{ ...image.gatsbyImageData, aspectRatio: aspectRatio }}
        alt={image?.alt ? image.alt : "Image"}
        className={className}
        imgClassName={imgClassName}
        loading="eager"
      />
    ) : (
      <img
        src={image?.url}
        alt={image?.alt ? image?.alt : "Image"}
        className={className}
        loading="eager"
      />
    )}
  </>
);

export default GatsbyImage;
