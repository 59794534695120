import { PrismicRichText } from "@prismicio/react";
import { Link } from "gatsby";
import React from "react";
import { useTrail } from "../../hooks/useTrail";
import Icon from "../../icon";
import { Paragraph } from "../Paragraph";

export const ServiceCard = ({ title, description, link, theme }) => {
  const TrailAnimationWrapper = useTrail({
    maxTranslationPx: 40,
  });

  return (
    <TrailAnimationWrapper
      Tag="div"
      className="flex flex-1 mt-4 md:mt-8 lg:mt-0 before:rounded-team after:rounded-team before:m-[1px] after:m-[1px] before:!w-[calc(100%-2px)] after:!w-[calc(100%-2px)] before:!h-[calc(100%-2px)] after:!h-[calc(100%-2px)]"
      theme={theme}
    >
      <Link
        to={link}
        className="block group p-4 bg-gray-100 rounded-team py-20 px-6 lg:px-8 lg:pt-12 lg:pb-28"
      >
        <PrismicRichText
          field={title.richText}
          components={{
            heading1: ({ children }) => (
              <h3
                className={`max-w-3xl text-2xl sm:text-3xl md:text-4.5xl font-display text-primary relative`}
              >
                <Icon
                  name="chevron"
                  className="absolute w-[8px] md:w-[9px] lg:w-[11px] right-0 top-1/2 -translate-y-1/2 -translate-x-[20px] transition-transform transition-duration-150 group-hover:translate-x-0"
                />
                {children}
              </h3>
            ),
          }}
        />
        <PrismicRichText
          field={description.richText}
          components={{
            paragraph: ({ children }) => <Paragraph>{children}</Paragraph>,
          }}
        />
      </Link>
    </TrailAnimationWrapper>
  );
};
