import React from "react";
import { graphql } from "gatsby";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import { getSeo } from "../utils/helpers";
import Layout from "../components/Layout";
import SlicesGeneral from "../components/SlicesGeneral";
import SEO from "../components/SEO/SEO";
import HeroHome from "../components/HeroHome";
import { useTrail } from "../hooks/useTrail";
import { LogosSlider } from "../components/sections";

const IndexPage = ({ data }) => {
  const { prismicNewHome: home } = data;
  console.log(data);

  if (!home) return <div>loading...</div>;

  const theme = home.data.page_theme
    ? home.data.page_theme
    : "theme-purple-400";

  const {
    title,
    description,
    hero_link_label,
    hero_link,
    hero_background_image,
  } = home.data;

  const TrailAnimationWrapper = useTrail();

  return (
    <Layout theme={theme}>
      <SEO
        title={title.text}
        desc={description.text}
        category={data?.type}
        prismicSeo={getSeo(home?.data?.body)}
      />
      <TrailAnimationWrapper className="" Tag="div" theme={theme}>
        <HeroHome
          title={title}
          intro={description}
          linkLabel={hero_link_label}
          linkUrl={hero_link}
          bgImage={hero_background_image}
        />
        <LogosSlider />
      </TrailAnimationWrapper>
      <div id="content" className="mt-12">
        <SlicesGeneral
          allSlices={home.data.body}
          theme={theme}
          pageTitle={title}
        />
      </div>
    </Layout>
  );
};

export const query = graphql`
  query IndexQuery {
    prismicNewHome {
      _previewable
      type
      data {
        title {
          text
          richText
        }
        description {
          text
          richText
        }
        page_theme
        body {
          ... on PrismicNewHomeDataBodyClutchReviews {
            id
            slice_type
          }
          ... on PrismicNewHomeDataBodyWhyChooseUs {
            id
            slice_type
          }
          ... on PrismicNewHomeDataBodySeo {
            id
            slice_type
            primary {
              seo_title
              seo_description
              seo_category
              seo_visitor_value
              seo_script {
                text
              }
              seo_image {
                url
              }
            }
          }
          ... on PrismicNewHomeDataBodyCta {
            id
            slice_type
            primary {
              cta_title {
                text
              }
              cta_body {
                text
                richText
              }
              cta_text {
                text
                richText
              }
              cta_link {
                url
                link_type
                target
                slug
                uid
                type
              }
              slice_theme
            }
          }
          ... on PrismicNewHomeDataBodyQuote {
            id
            slice_type
            primary {
              slice_theme
              quote_title {
                text
                richText
              }
              quote_name {
                text
                richText
              }
              quote_body {
                text
                richText
              }
              quote_image {
                alt
                gatsbyImageData
              }
            }
          }
          ... on PrismicNewHomeDataBodyPagelist {
            id
            slice_type
            primary {
              page_list_type
              page_list_title {
                text
                richText
              }
              page_list_body {
                text
                richText
              }
              button_link {
                url
                type
                uid
              }
              button_link_label
            }
            items {
              page_list_item_title {
                text
                richText
              }
              page_list_item_label: new_page_list_item_label {
                text
                richText
              }
              page_list_item_link {
                uid
                type
                link_type
                document {
                  ... on PrismicWork {
                    data {
                      title {
                        text
                        richText
                      }
                      description {
                        text
                        richText
                      }
                      image {
                        gatsbyImageData
                        large: gatsbyImageData
                      }
                    }
                  }
                  ... on PrismicNewWork {
                    data {
                      title {
                        text
                        richText
                      }
                      description {
                        text
                        richText
                      }
                      image: featured_image {
                        gatsbyImageData
                        large: gatsbyImageData
                      }
                      hero_background_image {
                        gatsbyImageData
                        large: gatsbyImageData
                      }
                      background_as_thumbnail
                    }
                  }
                  ... on PrismicCareer {
                    data {
                      title {
                        text
                        richText
                      }
                      description {
                        text
                        richText
                      }
                    }
                  }
                  ... on PrismicPost {
                    data {
                      title {
                        text
                        richText
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicNewHomeDataBodyBulletsSection {
            id
            slice_type
            primary {
              section_title {
                richText
              }
              section_content {
                richText
              }
            }
            items {
              bullet_text
            }
          }
          ... on PrismicNewHomeDataBodyLogosSlider {
            id
            slice_type
          }
          ... on PrismicNewHomeDataBodyServicesCards {
            id
            primary {
              button_label
              section_body {
                richText
              }
              section_title {
                richText
              }
              services_page_link {
                type
                uid
              }
            }
            slice_type
          }
          ... on PrismicNewHomeDataBodyDynamicForm {
            id
            slice_type
            primary {
              form_name
              section_title {
                richText
              }
              subline
              main_content {
                richText
              }
              slice_theme
            }
            items {
              name
              type
              placeholder
              required
            }
          }
          ... on PrismicNewHomeDataBodyCalendly {
            id
            slice_type
            primary {
              section_title {
                richText
              }
              subline
              main_content {
                richText
              }
              calendly_url
              slice_theme
              slice_layout
            }
          }
          ... on PrismicNewHomeDataBodyYoutubeVideo {
            id
            slice_type
            primary {
              section_title {
                richText
              }
              subline
              youtube_video_url
            }
          }
          ... on PrismicNewHomeDataBodyTallyEmbeddedForm {
            id
            slice_type
            primary {
              section_title {
                richText
              }
              subline
              main_content {
                richText
              }
              tally_form_id
              popup
              popup_button_text
              slice_theme
            }
          }
        }
        hero_link_label
        hero_link {
          url
          link_type
          target
          slug
          uid
          type
        }
        hero_background_image {
          gatsbyImageData
        }
      }
    }
  }
`;

export default withPrismicPreview(IndexPage);
