import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { graphql, useStaticQuery } from "gatsby";
import { useMergePrismicPreviewData } from "gatsby-plugin-prismic-previews";
import { linkResolver } from "../../utils/linkResolver";
import { Paragraph } from "../Paragraph";
import { ServiceCategorySection } from "../ServiceCategorySection";

export const ServicesSection = () => {
  const servicesData = useStaticQuery(serviceCategoriesQuery);
  const {
    data: { allPrismicServiceCategory },
  } = useMergePrismicPreviewData(servicesData);

  const { edges: services } = allPrismicServiceCategory;

  return (
    <div>
      {services.map(({ node: { data: service, uid: slug } }, index) => {
        const { icon_svg_code, title, description } = service;

        const reversed = index % 2 != 0;

        return (
          <div
            key={slug}
            id={slug}
            className={`px-5 sm:px-10 md:px-16 ${
              !reversed ? "bg-gray-100" : ""
            }`}
          >
            <div className="md:flex gap-x-16 max-w-screen-xl2 mx-auto py-12 md:py-24">
              <div
                className={`md:grow md:basis-1/2 ${
                  reversed ? "sm:order-2" : ""
                }`}
              >
                {icon_svg_code && (
                  <div
                    className="w-[156px] sm:w-[334px] mb-4 sm:mb-8"
                    dangerouslySetInnerHTML={{
                      __html: icon_svg_code.text,
                    }}
                  />
                )}
                <PrismicRichText
                  field={title.richText}
                  components={{
                    heading1: ({ children }) => (
                      <h2
                        className={`max-w-3xl text-2xl sm:text-3xl md:text-4.5xl font-display`}
                      >
                        {children}
                      </h2>
                    ),
                  }}
                />
                <PrismicRichText
                  field={description.richText}
                  linkResolver={linkResolver}
                  components={{
                    paragraph: ({ children }) => (
                      <Paragraph className="text-sm sm:text-lg md:mt-2">
                        {children}
                      </Paragraph>
                    ),
                  }}
                />
              </div>
              <div className="mt-10 md:mt-0 md:grow md:basis-1/2">
                <ServiceCategorySection categoryUid={slug} />
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

const serviceCategoriesQuery = graphql`
  query ServiceCategoriesQuery {
    allPrismicServiceCategory(sort: { first_publication_date: ASC }) {
      edges {
        node {
          data {
            title {
              richText
            }
            description {
              richText
            }
            icon_svg_code {
              text
            }
          }
          uid
        }
      }
    }
  }
`;
