import React, { FC } from "react";

import { uid } from "uid";
import { PrismicRichText } from "@prismicio/react";
import { linkResolver } from "../../utils/linkResolver";

import Section from "../Section";
import SectionText from "../SectionText";
import GatsbyImage from "../GatsbyImage";
import { SliceProps } from "../../types";

const Challenges: FC<SliceProps> = ({ data: { primary, items } }) => {
  const { challenges_title, challenges_body } = primary;

  return (
    <Section className="bg-gray-F9F9F9">
      <div className="mb-20">
        <SectionText
          label="CHALLENGES"
          headline={challenges_title.text}
          text={
            <PrismicRichText
              field={challenges_body.richText}
              linkResolver={linkResolver}
            />
          }
        />
      </div>

      {items && (
        <div className="grid gap-y-12 sm:grid-cols-2 sm:gap-x-20 sm:gap-y-16 lg:grid-cols-3">
          {items.map(item => {
            const {
              challenges_item_title,
              challenges_item_body,
              challenges_item_image,
            } = item;

            return (
              <div key={uid(16)}>
                {challenges_item_image && (
                  <GatsbyImage
                    image={challenges_item_image}
                    className="m-auto mb-8 xl:mb-10 bg-gray-200"
                  />
                )}

                {challenges_item_title.text && (
                  <h3 className="text-2xl leading-tight font-display mb-3">
                    {challenges_item_title.text}
                  </h3>
                )}

                {challenges_item_body.text && (
                  <p>{challenges_item_body.text}</p>
                )}
              </div>
            );
          })}
        </div>
      )}
    </Section>
  );
};

export default Challenges;
