import React, { FC } from "react";
import { PrismicRichText } from "@prismicio/react";
import { linkResolver } from "../../utils/linkResolver";
import { SliceProps } from "../../types";

import Section from "../Section";

const TextAndSVG: FC<SliceProps> = ({ data: { primary } }) => {
  const {
    textandsvg_label,
    textandsvg_title,
    textandsvg_body,
    textandsvg_svg,
  } = primary;

  return (
    <Section className="bg-gray-100 text-body">
      {textandsvg_label.text && (
        <div>
          <p className="text-pre uppercase">{textandsvg_label.text}</p>
        </div>
      )}
      <div className="grid gap-6 max-w-xl md:grid-cols-2 md:max-w-full md:gap-16">
        <div>
          {textandsvg_title.text && (
            <h2 className="text-h2 max-w-lg mb-10">{textandsvg_title.text}</h2>
          )}
          {textandsvg_body.richText.length > 0 && (
            <div className="content">
              <PrismicRichText
                field={textandsvg_body.richText}
                linkResolver={linkResolver}
              />
            </div>
          )}
        </div>

        {textandsvg_svg.text && (
          <figure
            className="hidden md:flex justify-center items-center"
            dangerouslySetInnerHTML={{
              __html: textandsvg_svg.text,
            }}
          />
        )}
      </div>
    </Section>
  );
};

export default TextAndSVG;
