import React, { FC } from "react";
import { uid } from "uid";
import Section from "../Section";
import SectionText from "../SectionText";
import GatsbyImage from "../GatsbyImage";
import { SliceProps } from "../../types";

const MobileSideBySide: FC<SliceProps> = ({ data: { primary, items } }) => {
  const {
    mobilesidebyside_title,
    mobilesidebyside_body,
    mobilesidebyside_has_text_top,
  } = primary;

  const hasTextTop = !!mobilesidebyside_has_text_top;

  return (
    <>
      {hasTextTop && (
        <Section paddingY="pt-16 sm:pt-20 lg:pt-32">
          <SectionText
            label="SOLUTION"
            headline={mobilesidebyside_title.text}
            text={mobilesidebyside_body.text}
          />
        </Section>
      )}
      {items && (
        <Section className="order-2">
          <div className="grid gap-10 sm:grid-cols-2 sm:gap-10 lg:grid-cols-3 md:gap-20">
            {items.map(item => (
              <GatsbyImage
                key={uid(16)}
                image={item.mobilesidebyside_item_image}
                className="bg-gray-200 min-h-[600px] bg-transparent"
              />
            ))}
          </div>
        </Section>
      )}
      {!hasTextTop && (
        <Section paddingY="pb-16 sm:pb-20 lg:pb-32">
          <SectionText
            label="SOLUTION"
            headline={mobilesidebyside_title.text}
            text={mobilesidebyside_body.text}
          />
        </Section>
      )}
    </>
  );
};

export default MobileSideBySide;
