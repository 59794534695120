import React from "react";
import { graphql } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import { linkResolver } from "../utils/linkResolver";
import { getSeo, getSliceData } from "../utils/helpers";
import {
  useMergePrismicPreviewData,
  withPrismicPreview,
} from "gatsby-plugin-prismic-previews";

import Layout from "../components/Layout";
import Section from "../components/Section";
import GatsbyImage from "../components/GatsbyImage";

import SlicesWork from "../components/SlicesWork";
import Seo from "../components/SEO/SEO";
import { WorkHero } from "../components/work/WorkHero";

const Work = ({ data }) => {
  const {
    data: { prismicNewWork },
  } = useMergePrismicPreviewData(data);
  const work = prismicNewWork.data;

  if (!work) return <div>loading...</div>;

  const {
    title,
    description,
    featured_image,
    hero_background_image,
    image_logo,
    background_image_has_gradient_overlay,
  } = work;

  const theme = work.page_theme || "theme-purple-400";

  return (
    <Layout
      theme={theme}
      transparentBG={hero_background_image?.url ? true : false}
    >
      <WorkHero
        bgImage={hero_background_image?.url}
        featuredImage={featured_image}
        hasImageOverlay={background_image_has_gradient_overlay}
      />
      <Seo
        title={work.title.text}
        desc={work.description.text}
        category={data.prismicNewWork.type}
        visitorValue="high"
        prismicSeo={getSeo(work?.body)}
        pageColors={getSliceData(work?.body, "theme_slice")}
      />

      <Section
        paddingY="py-0"
        className="t-page max-w-8xl m-auto xl:bg-white xl:rounded-t-md"
      >
        <div className="m-auto pt-6 md:pt-12 xl:pt-16 xl:pb-0">
          <div className="md:grid md:grid-cols-[2fr_1fr] md:gap-20 bg-white items-center">
            <div>
              {title.text && (
                <h1 className="font-display text-2xl md:text-4.5xl mb-6">
                  {title.text}
                </h1>
              )}

              {description?.richText?.length > 0 && (
                <div className="content">
                  <PrismicRichText
                    field={description.richText}
                    linkResolver={linkResolver}
                  />
                </div>
              )}
            </div>

            {image_logo && (
              <div className="hidden md:flex py-16 md:py-0 justify-center">
                <GatsbyImage
                  image={image_logo}
                  aspectRatio={1}
                  className="max-w-xss xl:max-w-xs m-auto xl:mt-4"
                />
              </div>
            )}
          </div>
        </div>
      </Section>
      <SlicesWork allSlices={work.body} theme={theme} />
    </Layout>
  );
};

export const query = graphql`
  query WorkQuery($uid: String!) {
    prismicNewWork(uid: { eq: $uid }) {
      _previewable
      type
      uid
      data {
        title {
          text
          richText
        }
        description {
          text
          richText
        }
        image_logo {
          url
          gatsbyImageData
        }
        featured_image {
          url
          gatsbyImageData
        }
        hero_background_image {
          url
          gatsbyImageData
        }
        background_image_has_gradient_overlay
        page_theme
        body {
          ... on PrismicNewWorkDataBodySeo {
            slice_type
            primary {
              seo_title
              seo_description
              seo_category
              seo_visitor_value
              seo_script {
                text
              }
              seo_image {
                url
              }
            }
          }
          ... on PrismicNewWorkDataBodyThemeSlice {
            id
            slice_type
            primary {
              primary_color
              secondary_color
              secondary_color_hover
              tertiary_color
            }
          }
          ... on PrismicNewWorkDataBodyText {
            id
            slice_type
            primary {
              text_label {
                text
                richText
              }
              text_title {
                text
                richText
              }
              text_title_size
              text_body_size
              text_variant
              slice_theme
            }
            items {
              text_body {
                text
                richText
              }
            }
          }
          ... on PrismicNewWorkDataBodyNewText {
            id
            slice_type
            primary {
              new_text_label: text_label
              new_text_title: text_title {
                text
                richText
              }
              text_centered
              text_layout
              text_remove_padding_bottom
              text_span_title
              slice_gray_background
              slice_inverted_colours
              slice_theme
            }
            items {
              column_body {
                text
                richText
              }
            }
          }
          ... on PrismicNewWorkDataBodyQuote {
            id
            slice_type
            primary {
              quote_title {
                text
                richText
              }
              quote_name {
                text
                richText
              }
              quote_body {
                text
                richText
              }
              quote_image {
                alt
                gatsbyImageData
              }
              slice_gray_background
              slice_inverted_colours
              slice_theme
            }
          }
          ... on PrismicNewWorkDataBodyChallenges {
            slice_type
            primary {
              challenges_title {
                text
                richText
              }
              challenges_body {
                text
                richText
              }
            }
            items {
              challenges_item_title {
                text
                richText
              }
              challenges_item_body {
                text
                richText
              }
              challenges_item_image {
                gatsbyImageData
              }
            }
          }
          ... on PrismicNewWorkDataBodyNewSolutionCarousel {
            slice_type
            primary {
              solution_carousel_title {
                text
                richText
              }
              solution_carousel_body {
                text
                richText
              }
              slice_gray_background
              slice_inverted_colours
              slice_theme
            }
            items {
              solution_carousel_item_image {
                gatsbyImageData
              }
              solution_carousel_item_description {
                text
                richText
              }
            }
          }
          ... on PrismicNewWorkDataBodyBulletsSection {
            id
            slice_type
            items {
              bullet_text
            }
            primary {
              section_title {
                richText
              }
              section_content {
                richText
              }
            }
          }
          ... on PrismicNewWorkDataBodyImagesSlider {
            id
            slice_type
            primary {
              slice_gray_background
              slice_inverted_colours
              slice_theme
            }
            items {
              images_slider_item_image {
                gatsbyImageData
              }
            }
          }
          ... on PrismicNewWorkDataBodyMobilesidebyside {
            slice_type
            primary {
              mobilesidebyside_title {
                text
                richText
              }
              mobilesidebyside_body {
                text
                richText
              }
              mobilesidebyside_has_text_top
            }
            items {
              mobilesidebyside_item_image {
                gatsbyImageData
              }
            }
          }
          ... on PrismicNewWorkDataBodyPagelist {
            id
            slice_type
            primary {
              page_list_type
              page_list_title {
                text
                richText
              }
              page_list_body {
                text
                richText
              }
              slice_theme
            }
            items {
              page_list_item_title {
                text
                richText
              }
              page_list_item_label {
                text
                richText
              }
              page_list_item_link {
                uid
                type
                type
                link_type
                document {
                  ... on PrismicWork {
                    data {
                      title {
                        text
                        richText
                      }
                      description {
                        text
                        richText
                      }
                      image {
                        gatsbyImageData
                        large: gatsbyImageData
                      }
                    }
                  }
                  ... on PrismicNewWork {
                    data {
                      title {
                        text
                        richText
                      }
                      description {
                        text
                        richText
                      }
                      image: featured_image {
                        gatsbyImageData
                        large: gatsbyImageData
                      }
                      hero_background_image {
                        gatsbyImageData
                        large: gatsbyImageData
                      }
                      background_as_thumbnail
                    }
                  }
                  ... on PrismicCareer {
                    data {
                      title {
                        text
                        richText
                      }
                      description {
                        text
                        richText
                      }
                    }
                  }
                  ... on PrismicPost {
                    data {
                      title {
                        text
                        richText
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicNewWorkDataBodyDynamicForm {
            id
            slice_type
            primary {
              form_name
              section_title {
                richText
              }
              subline
              main_content {
                richText
              }
              slice_theme
            }
            items {
              name
              type
              placeholder
              required
            }
          }
          ... on PrismicNewWorkDataBodyTallyEmbeddedForm {
            id
            slice_type
            primary {
              section_title {
                richText
              }
              subline
              main_content {
                richText
              }
              tally_form_id
              popup
              popup_button_text
              slice_theme
            }
          }
          ... on PrismicNewWorkDataBodyCta {
            id
            slice_type
            primary {
              cta_title {
                text
              }
              cta_body {
                text
                richText
              }
              cta_text {
                text
              }
              cta_link {
                url
                type
                link_type
                target
                slug
                uid
              }
              slice_theme
              visible
            }
          }
        }
      }
    }
  }
`;

export default withPrismicPreview(Work);
