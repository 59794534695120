import React from "react";

const SVG = ({
  style = {},
  width = "1.75rem",
  height = "1.75rem",
  className = "",
  viewBox = "0 0 3619.61 3619.61",
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    className={`svg-icon fill-current transition duration-300 ease-in-out ${
      className || ""
    }`}
    clipRule="evenodd"
    fillRule="evenodd"
    imageRendering="optimizeQuality"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="m3203.74 2.7h-2793.26c-227.28 0-413.17 185.89-413.17 413.17v2793.26c0 227.28 185.89 413.17 413.17 413.17h2793.26c227.28 0 413.17-185.89 413.17-413.17v-2793.26c0-227.29-185.89-413.17-413.17-413.17z" />
    <g fill="#fff" fillRule="nonzero">
      <path d="m2244.94 776.81h-875.63c-330.2 0-597.88 267.68-597.88 597.88v875.63c0 330.2 267.68 597.88 597.88 597.88h875.63c330.2 0 597.87-267.68 597.87-597.88v-875.63c0-330.2-267.68-597.88-597.87-597.88zm407.11 1488.9c0 219.19-177.69 396.87-396.87 396.87h-896.13c-219.19 0-396.87-177.69-396.87-396.87v-896.12c0-219.19 177.69-396.87 396.87-396.87h896.13c219.18 0 396.87 177.69 396.87 396.87z" />
      <path d="m1807.13 1280.2c-296.83 0-537.45 240.62-537.45 537.45s240.63 537.45 537.45 537.45c296.83 0 537.45-240.63 537.45-537.45 0-296.83-240.63-537.45-537.45-537.45zm0 885.85c-192.42 0-348.4-155.98-348.4-348.4 0-192.41 155.98-348.4 348.4-348.4 192.41 0 348.4 155.98 348.4 348.4s-155.98 348.4-348.4 348.4z" />
      <path d="m2362.5 1134.55c-69.15 0-125.21 56.06-125.21 125.2 0 69.15 56.06 125.21 125.21 125.21s125.2-56.06 125.2-125.21-56.06-125.2-125.2-125.2z" />
    </g>
  </svg>
);

export default SVG;
