import React from "react";
import { linkResolver } from "../../utils/linkResolver";
import { PrismicRichText } from "@prismicio/react";

const Content = ({ data: { primary } }) => {
  const { post_content } = primary;

  return (
    <>
      {post_content.richText.length > 0 && (
        <div className="px-5 sm:px-10 prose max-w-3xl m-auto py-6">
          <PrismicRichText
            field={post_content.richText}
            linkResolver={linkResolver}
          />
        </div>
      )}
    </>
  );
};

export default Content;
