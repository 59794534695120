import React from "react";
import { PrismicRichText } from "@prismicio/react";
import { linkResolver } from "../../utils/linkResolver";
import Section from "../Section";
import GatsbyImage from "../GatsbyImage";
import { Heading2 } from "../Heading2";
import { Paragraph } from "../Paragraph";
import clsx from "clsx";

const Quote = ({ data: { primary }, theme }) => {
  const {
    quote_title,
    quote_name,
    quote_body,
    quote_image,
    slice_gray_background,
    slice_inverted_colours,
    slice_theme,
  } = primary;

  const dotsBackgroundColorClass = slice_inverted_colours
    ? "before:bg-white after:bg-white"
    : "before:bg-primary after:bg-primary";

  return (
    <Section
      className={clsx(
        "pl-0 xs:pl-8 lg:pl-0",
        slice_gray_background && "bg-gray-100",
        slice_inverted_colours && "bg-primary text-white",
        slice_theme
      )}
      paddingY="py-8 sm:py-20 lg:py-24"
    >
      <div className="grid gap-2 lg:grid-cols-[auto_1fr] lg:gap-28">
        <div>
          <GatsbyImage
            image={quote_image}
            className="xs:max-w-[343px] xs:block md:max-w-[406px] lg:ml-0 || bg-img bg-img--white rounded-md sm:rounded-team"
          />
        </div>
        <blockquote className="relative flex flex-col justify-center lg:text-left sm:mx-auto lg:mx-0">
          <div
            className={clsx(
              "hidden xs:flex relative mb-1 lg:mx-0 before:w-1.5 -left-9 top-8 sm:top-9 md:top-6 lg:top-6 before:h-1.5 before:rounded-[1.4px] before:mr-2 after:w-1.5 after:h-1.5 after:rounded-[1.4px]",
              dotsBackgroundColorClass
            )}
          />
          {quote_title.text && (
            <div className="-mt-[6px]">
              <Heading2 className="ml-0 mt-4 md:mt-0">
                {quote_title.text}
              </Heading2>
            </div>
          )}
          {quote_body && (
            <PrismicRichText
              field={quote_body.richText}
              linkResolver={linkResolver}
              components={{
                paragraph: ({ children }) => (
                  <Paragraph className="md:mt-4">{children}</Paragraph>
                ),
              }}
            />
          )}
          {quote_name && (
            <Paragraph className="md:!mt-4 lowercase font-display">
              {quote_name.text}
            </Paragraph>
          )}
        </blockquote>
      </div>
    </Section>
  );
};

export default Quote;
