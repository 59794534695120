import React from "react";
import { Heading2 } from "../Heading2";
import { Paragraph } from "../Paragraph";
import { PrismicRichText } from "@prismicio/react";
import GatsbyImage from "../GatsbyImage";
import { linkResolver } from "../../utils/linkResolver";
import Section from "../Section";
import clsx from "clsx";

export const ExtendedTeamSection = ({ data: { primary } }) => {
  const { section_bg_image, section_title, section_body, slice_theme } =
    primary;

  return (
    <div
      className={clsx(
        "relative bg-primary py-3 md:py-24 z-0 text-white",
        "before:content-[''] before:absolute before:top-0 before:left-0 before:right-0 before:bottom-0 before:-z-[1] before:bg-section-gradient",
        slice_theme
      )}
    >
      <GatsbyImage
        image={section_bg_image}
        className="!absolute -z-[2] top-0 bottom-0 right-0 left-0 w-full opacity-20 border-b-2 border-themePurple-600 max-w-screen-xl3 mx-auto"
      />
      <Section paddingY="py-24">
        <PrismicRichText
          field={section_title.richText}
          components={{
            heading2: ({ children }) => (
              <Heading2 className="ml-0">{children}</Heading2>
            ),
          }}
          linkResolver={linkResolver}
        />
        <PrismicRichText
          field={section_body.richText}
          components={{
            paragraph: ({ children }) => (
              <Paragraph className="max-w-xl">{children}</Paragraph>
            ),
          }}
          linkResolver={linkResolver}
        />
      </Section>
    </div>
  );
};
