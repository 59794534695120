import React from "react";

import Section from "../Section";
import Address from "../Address";
import Contact from "../Contact";

const ContactSection = ({ data, theme }) => {
  return (
    <Section>
      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-10 xl:gap-x-20">
        <div className="pt-1 mb-10">
          <div className="max-w-xl xl3:max-w-3xl grid grid-cols-1 gap-y-8 lg:grid-cols-2 lg:gap-y-10 lg:gap-x-12 xl3:grid-cols-3 xl3:gap-x-16 leading-snug">
            <Address />
          </div>
        </div>

        <Contact theme={theme} />
      </div>
    </Section>
  );
};

export default ContactSection;
