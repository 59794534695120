import React from "react";
import { uid } from "uid";

import { Content, Embed, Image, Youtube } from "./blog";
import {
  CalendlySection,
  Cta,
  RelatedPosts,
  TallyEmbeddedForm,
  VideoSection,
} from "./sections";

const SlicesBlog = ({ allSlices }) => {
  if (!allSlices) return null;

  const slice = allSlices.map(slice => {
    switch (slice.slice_type) {
      case "content":
        return <Content key={uid(16)} data={slice} />;
      case "embed":
        return <Embed key={uid(16)} data={slice} />;
      case "image":
        return <Image key={uid(16)} data={slice} />;
      case "youtube":
        return <Youtube key={uid(16)} data={slice} />;
      case "cta":
        return <Cta key={uid(16)} data={slice} />;
      case "related_posts":
        return <RelatedPosts key={uid(16)} data={slice} />;
      case "calendly":
        return <CalendlySection key={uid(16)} data={slice} />;
      case "youtube_video":
        return <VideoSection key={uid(16)} data={slice} />;
      case "tally_embedded_form":
        return <TallyEmbeddedForm key={uid(16)} data={slice} />;
      default:
        return null;
    }
  });
  return slice;
};

export default SlicesBlog;
