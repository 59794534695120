import React from "react";

const EmailIcon = ({
  style = {},
  width = "1.75rem",
  height = "1.75rem",
  className = "",
  viewBox = "0 0 3619.61 3619.61",
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={`svg-icon fill-current transition duration-300 ease-in-out ${
      className || ""
    }`}
  >
    <g clipPath="url(#clip0_1231_73)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.3745 0H2.6254C1.18113 0 0 1.1812 0 2.6254V20.3745C0 21.8187 1.1812 22.9999 2.6254 22.9999H20.3745C21.8187 22.9999 22.9999 21.8187 22.9999 20.3745V2.6254C22.9999 1.18113 21.8187 0 20.3745 0V0Z"
        fill="currentColor"
      />
      <path
        d="M18.2879 6.16504C18.947 6.16504 19.5 6.71806 19.5 7.37716L19.5 15.6196C19.5 16.2787 18.947 16.8317 18.2879 16.8317L4.71212 16.8317C4.05302 16.8317 3.5 16.2787 3.5 15.6196L3.5 7.37716C3.5 6.71806 4.05302 6.16504 4.71212 6.16504L18.2879 6.16504ZM16.803 7.61958L6.19697 7.61958L11.5 12.2256L16.803 7.61958ZM18.0455 8.46807L14.6743 11.3922L18.0455 14.4603L18.0455 8.46807ZM4.95454 8.46807L4.95454 14.4606L8.3257 11.3924L4.95454 8.46807ZM13.5681 12.3619L11.9772 13.7407C11.7035 13.9784 11.2963 13.9784 11.0227 13.7407L9.43176 12.3619L6.11345 15.3772L16.8863 15.3772L13.5681 12.3619Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1231_73">
        <rect width="23" height="23" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default EmailIcon;
