import React from "react"

import SwiperCore, { Autoplay, EffectFade } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import frame1 from "../images/plandisc/frame-1.png"
import frame2 from "../images/plandisc/frame-2.png"
import frame3 from "../images/plandisc/frame-3.png"
import frame4 from "../images/plandisc/frame-4.png"
import frameBg from "../images/plandisc/frame-bg.png"

SwiperCore.use([Autoplay, EffectFade])

const ResizeScreen = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${frameBg})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Swiper
        className="mb-16"
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        effect="fade"
        loop
        pagination={{
          clickable: true,
          renderBullet: (index, className) => {
            return `<span class="${className}"></span>`
          },
        }}
      >
        <SwiperSlide className="resizeScreenSlide">
          {({ isActive }) => (
            <>
              <img
                src={frame1}
                alt="Slide 1"
                className={`${
                  isActive ? "opacity-100" : "opacity-0"
                } duration-300 ease-in`}
              />
            </>
          )}
        </SwiperSlide>
        <SwiperSlide className="resizeScreenSlide">
          {({ isActive }) => (
            <>
              <img
                src={frame2}
                alt="Slide 2"
                className={`${
                  isActive ? "opacity-100" : "opacity-0"
                } duration-300 ease-in`}
              />
            </>
          )}
        </SwiperSlide>
        <SwiperSlide className="resizeScreenSlide">
          {({ isActive }) => (
            <>
              <img
                src={frame3}
                alt="Slide 3"
                className={`${
                  isActive ? "opacity-100" : "opacity-0"
                } duration-300 ease-in`}
              />
            </>
          )}
        </SwiperSlide>
        <SwiperSlide className="resizeScreenSlide">
          {({ isActive }) => (
            <>
              {isActive && (
                <img
                  src={frame4}
                  alt="Slide 4"
                  className={`${
                    isActive ? "opacity-100" : "opacity-0"
                  } duration-300 ease-in`}
                />
              )}
            </>
          )}
        </SwiperSlide>
      </Swiper>
    </div>
  )
}

export default ResizeScreen
