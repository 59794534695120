import { PrismicRichText } from "@prismicio/react";
import clsx from "clsx";
import React, { FC } from "react";
import { linkResolver } from "../utils/linkResolver";
import Button from "./Button";
import GatsbyImage from "./GatsbyImage";

const HeroTitle = ({ title }) => (
  <PrismicRichText
    field={title.richText}
    components={{
      heading1: ({ children }) => (
        <h1 className="text-3xl xs:text-5xl md:text-6xl font-display xl2:pt-2">
          {children}
        </h1>
      ),
      strong: ({ children }) => (
        <strong className="text-secondary font-normal">{children}</strong>
      ),
    }}
  />
);

const HeroIntro = ({ intro }) => (
  <div className="grid grid-cols-1 row-gap-6 leading-snug mt-4">
    <PrismicRichText
      field={intro.richText}
      linkResolver={linkResolver}
      components={{
        paragraph: ({ children }) => (
          <p className="text-sm xs:text-lg">{children}</p>
        ),
      }}
    />
  </div>
);

interface IHeroHomeProps {
  title: any;
  intro: any;
  linkUrl: string;
  linkLabel: string;
  bgImage: any;
}

export const HeroHome: FC<IHeroHomeProps> = ({
  title,
  intro,
  linkUrl,
  linkLabel,
  bgImage,
}) => (
  <section className="text-white bg-primary">
    <div
      className={clsx(
        "container || relative z-0 flex flex-col justify-center text-center md:flex-row lg:pb-0 md:fit-content max-w-screen-xl3 mx-auto",
        "before:content-[''] before:absolute before:top-0 before:left-0 before:right-0 before:bottom-0 before:-z-[1] before:bg-hero-gradient"
      )}
    >
      <GatsbyImage
        image={bgImage}
        className="!absolute -z-[2] top-0 bottom-0 right-0 left-0 w-full opacity-20 border-b-2 border-themePurple-600 max-w-screen-xl3 mx-auto"
      />
      <div className="pb-16 pt-28 md:pb-32 md:pt-60">
        {title && <HeroTitle title={title} />}
        {intro && <HeroIntro intro={intro} />}
        <Button className="mt-8" linkMeta={linkUrl}>
          {linkLabel}
        </Button>
      </div>
    </div>
  </section>
);

export default HeroHome;
