import { PrismicRichText } from "@prismicio/react";
import React from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { BlogPost } from "../BlogPost";
import { Heading2 } from "../Heading2";
import Section from "../Section";

export const RelatedPosts = ({ data: { primary, items } }) => {
  const { section_title } = primary;

  return (
    <Section className="bg-gray-100" paddingY="py-16 sm:py-20 lg:py-24">
      {section_title && (
        <div className="text-center mb-8 sm:mb-16">
          <PrismicRichText
            field={section_title.richText}
            components={{
              heading2: ({ children }) => (
                <Heading2 className="sm:text-center">{children}</Heading2>
              ),
            }}
          />
        </div>
      )}

      {items && (
        <Swiper
          modules={[Pagination]}
          className="related-posts page-list relative pb-20"
          breakpoints={{
            786: {
              spaceBetween: 60,
              slidesPerView: 2,
            },
            320: {
              spaceBetween: 20,
              centeredSlides: true,
              slidesPerView: 1,
            },
          }}
          // loop
          slidesPerView="auto"
          grabCursor
          pagination={{
            clickable: true,
            renderBullet: (index, className) => {
              return `<span class="${className}"></span>`;
            },
          }}
        >
          {items.map(({ related_post }) => {
            const { uid } = related_post.document;
            const { title, author, description, image } =
              related_post.document.data;

            return (
              <SwiperSlide key={uid}>
                <BlogPost
                  title={title}
                  author={author}
                  description={description}
                  imageSharp={image}
                  meta={related_post.document}
                />
              </SwiperSlide>
            );
          })}
        </Swiper>
      )}
    </Section>
  );
};
