import React from "react";
import { useTrail } from "../../hooks/useTrail";
import { Hero } from "../NewHero";
import { LogosSlider } from "./LogosSlider";

export const HeroSlice = ({ data: { primary }, pageTitle }) => {
  const {
    hero_animation,
    hero_animation_file,
    hero_cta_label,
    hero_cta_link,
    hero_image_svg_code,
    hero_intro,
    hero_title,
    hero_use_page_title,
    hero_show_logos_slider,
    hero_logos_slider_theme,
    slice_theme,
  } = primary;

  const TrailAnimationWrapper = useTrail();

  return (
    <TrailAnimationWrapper className="" Tag="div" theme={slice_theme}>
      <Hero
        title={hero_use_page_title ? pageTitle : hero_title}
        intro={hero_intro}
        imageSVGCode={hero_image_svg_code}
        animation={hero_animation}
        animationFile={hero_animation_file}
        buttonLabel={hero_cta_label}
        buttonLink={hero_cta_link.url}
        theme={slice_theme}
      />
      {hero_show_logos_slider && (
        <LogosSlider theme={hero_logos_slider_theme || slice_theme} />
      )}
    </TrailAnimationWrapper>
  );
};
