import React, { FC, PropsWithChildren } from "react";

type SectionProps = {
  className?: string;
  fullWidth?: boolean;
  paddingY?: string;
  style?: any;
};

const Section: FC<PropsWithChildren<SectionProps>> = ({
  children,
  className = "",
  fullWidth = false,
  paddingY = "py-8 md:py-16 sm:py-20 lg:py-24",
  style,
}) => {
  return (
    <section className={className} style={style}>
      <div className={`px-5 m-auto sm:px-10 lg:px-16 ${paddingY}`}>
        <div className={`m-auto ${fullWidth ? "max-w-full" : "max-w-7xl"}`}>
          {children}
        </div>
      </div>
    </section>
  );
};

export default Section;
