import React from "react";

const FacebookIcon = ({
  style = {},
  width = "1.75rem",
  height = "1.75rem",
  className = "",
  viewBox = "0 0 3619.61 3619.61",
}) => (
  <svg
    width={width}
    style={style}
    height={width}
    viewBox={viewBox}
    className={`svg-icon fill-current transition duration-300 ease-in-out ${
      className || ""
    }`}
    clipRule="evenodd"
    fillRule="evenodd"
    imageRendering="optimizeQuality"
    shapeRendering="geometricPrecision"
    textRendering="geometricPrecision"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path d="m3205.94 2.46h-2793.26c-227.29 0-413.17 185.89-413.17 413.17v2793.26c0 227.28 185.89 413.17 413.17 413.17h2793.26c227.28 0 413.17-185.89 413.17-413.17v-2793.26c0-227.29-185.89-413.17-413.17-413.17z" />
    <path
      d="m1512.4 2379.36c0-178.33-.69-356.66.65-534.96.24-30.06-8.26-39.46-38.32-38.23-55.57 2.26-111.29-.23-166.93 1.05-22.53.57-30.83-5.67-30.58-29.53 1.12-111.26 1.01-222.58.11-333.87-.2-21.4 6.25-28.44 27.89-27.96 56.89 1.31 113.9-1.59 170.69 1.22 32.91 1.61 39.72-10.08 39.04-40.62-1.98-84.67-1.98-169.48-.13-254.17 2.26-103.43 30.02-200 91.63-284.1 77.96-106.5 188.8-155.19 316.68-160.89 131.33-5.83 262.99-2.6 394.48-4.01 18.33-.21 23.8 6.33 23.72 24.16-.58 112.54-.7 225.11.08 337.65.18 20.02-6.36 26.15-26.01 25.83-63.24-.99-126.5-.51-189.7-.33-84.17.26-134.55 43.56-139.83 126.88-4.23 66.8-1.14 134-2.69 200.99-.57 23.87 13.61 22.23 29.4 22.21 96.09-.14 192.24.96 288.33-.69 28.21-.49 37.52 6 34.3 35.7-12.09 111.82-22.49 223.85-32.07 335.94-2.19 25.29-13.73 30.11-36.45 29.73-75.81-1.17-151.7-.8-227.59-.32-54.49.32-49.2-7.98-49.2 47.59-.25 349.05-.72 698.11.78 1047.14.15 36.86-9.27 46.01-45.74 45.37-130.23-2.4-260.53-2.52-390.76.06-36.7.72-42.75-11.72-42.46-44.46 1.39-175.78.69-351.57.69-527.37z"
      fill="#fefefe"
    />
  </svg>
);

export default FacebookIcon;
