import React, { FC } from "react";
import { PrismicRichText } from "@prismicio/react";
import { linkResolver } from "../../utils/linkResolver";

import Section from "../Section";
import GatsbyImage from "../GatsbyImage";
import { SliceProps } from "../../types";
import clsx from "clsx";

const FullWidthImage: FC<SliceProps> = ({ data: { primary } }) => {
  const {
    fullwidthimage_image,
    fullwidthimage_label,
    fullwidthimage_title,
    fullwidthimage_title_max_width,
    fullwidthimage_image_bottom,
    fullwidthimage_body,
    slice_gray_background,
  } = primary;

  const hasText = fullwidthimage_title.text || fullwidthimage_body.text;
  const hasTextBottom = fullwidthimage_image_bottom;
  const maxWidthClass = fullwidthimage_title_max_width;

  return (
    <section
      className={clsx(
        `text-body flex flex-wrap`,
        slice_gray_background && "bg-gray-100"
      )}
    >
      {hasText && (
        <div className={`w-full ${hasTextBottom ? "order-2" : "order-1"}`}>
          <Section>
            {fullwidthimage_label.text && (
              <div>
                <p className="text-pre">{fullwidthimage_label.text}</p>
              </div>
            )}

            <div className="grid gap-6 max-w-xl lg:grid-cols-2 lg:max-w-full lg:gap-16">
              {fullwidthimage_title.text && (
                <div>
                  <h2
                    className={`text-h2 ${
                      maxWidthClass ? maxWidthClass : "max-w-lg"
                    }`}
                  >
                    {fullwidthimage_title.text}
                  </h2>
                </div>
              )}

              {fullwidthimage_body.richText.length > 0 && (
                <div className="content">
                  <PrismicRichText
                    field={fullwidthimage_body.richText}
                    linkResolver={linkResolver}
                  />
                </div>
              )}
            </div>
          </Section>
        </div>
      )}
      {fullwidthimage_image && (
        <div className={`w-full ${hasTextBottom ? "order-1" : "order-2"}`}>
          <GatsbyImage
            image={fullwidthimage_image}
            className="w-full bg-gray-200"
          />
        </div>
      )}
    </section>
  );
};

export default FullWidthImage;
