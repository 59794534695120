import React, { FC, PropsWithChildren } from "react";
import { linkResolver } from "../utils/linkResolver";

import { Link } from "gatsby";
import clsx from "clsx";

declare global {
  interface Window {
    dataLayer: any;
  }
}

type IButtonProps = {
  href?: string;
  to?: string;
  linkMeta?: any;
  className?: string;
};

export const buttonCls = `
  inline-block
  py-3
  xs:py-3.5
  px-8
  leading-4
  xs:leading-6
  md:leading-7
  text-white
  text-sm
  xs:text-base
  md:text-lg
  font-semibold
  rounded-default
  cursor-pointer
  duration-300
  ease-in-out
  bg-secondary
  hover:bg-secondary-hover
  tracking-wide
`;

const Button: FC<PropsWithChildren<IButtonProps>> = ({
  href = "",
  children,
  to,
  linkMeta,
  className,
}) => {
  const internalLink = to || linkResolver(linkMeta);

  const handleClick = () => {
    if (href.includes("mailto")) return;

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: `${internalLink ? "Inbound" : "Outbound"}-Button-Click`,
      label: window.location.href,
    });
  };

  return (
    <>
      {internalLink && (
        <Link
          to={internalLink}
          className={clsx(buttonCls, className)}
          onClick={handleClick}
        >
          {children}
        </Link>
      )}
      {href && (
        <a
          href={href}
          className={clsx(buttonCls, className)}
          onClick={handleClick}
        >
          {children}
        </a>
      )}
    </>
  );
};

export default Button;
