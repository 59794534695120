import React, { Fragment } from "react";
import { Link } from "gatsby";
import { PrismicRichText } from "@prismicio/react";
import { linkResolver } from "../../utils/linkResolver";
import { uid } from "uid";
import Section from "../Section";
import GatsbyImage from "../GatsbyImage";
import Button from "../Button";
import { Paragraph } from "../Paragraph";
import { Heading2 } from "../Heading2";
import clsx from "clsx";

export const PageListGallery = ({ data: { primary, items } }) => {
  const {
    page_list_title,
    page_list_body,
    button_link,
    button_link_label,
    slice_theme,
  } = primary;

  return (
    <Section
      paddingY={clsx("py-16 sm:py-20 lg:py-24 bg-grey-100", slice_theme)}
    >
      {page_list_title && (
        <Heading2 className="sm:text-center">{page_list_title.text}</Heading2>
      )}

      {page_list_body && (
        <PrismicRichText
          field={page_list_body.richText}
          linkResolver={linkResolver}
          components={{
            paragraph: ({ children }) => (
              <Paragraph className="sm:text-center">{children}</Paragraph>
            ),
          }}
        />
      )}

      {items && (
        <div className="page-list mt-8 sm:mt-16">
          {items.map(item => {
            const {
              page_list_item_title,
              page_list_item_label,
              page_list_item_link,
            } = item;

            if (!page_list_item_title && !page_list_item_link) return null;

            const {
              title,
              image,
              hero_background_image,
              background_as_thumbnail,
            } = page_list_item_link.document.data;
            const titleText = page_list_item_title || title;

            return (
              <Fragment key={uid(16)}>
                <article className="page-list__item">
                  <Link
                    className="page-list__link"
                    to={linkResolver(page_list_item_link)}
                  >
                    {image && (
                      <GatsbyImage
                        className="page-list__image h-0"
                        image={
                          image.gatsbyImageData.width == 1 ||
                          background_as_thumbnail
                            ? hero_background_image
                            : image
                        }
                      />
                    )}
                    <div className="page-list__text">
                      <div className="page-list__textContent text-base">
                        {titleText && (
                          <h3
                            className="text-h3 mb-5"
                            style={{ fontSize: "30px" }}
                          >
                            {titleText.text}
                          </h3>
                        )}
                        {page_list_item_label && (
                          <p className="text-sm">{page_list_item_label.text}</p>
                        )}
                      </div>
                    </div>
                  </Link>
                </article>
              </Fragment>
            );
          })}
        </div>
      )}

      {button_link && (
        <div className="mt-16 flex justify-center">
          <Button linkMeta={button_link}>{button_link_label}</Button>
        </div>
      )}
    </Section>
  );
};
