import React, { FC } from "react";
import { PrismicRichText } from "@prismicio/react";
import { Heading2 } from "../Heading2";
import { uid } from "uid";
import { linkResolver } from "../../utils/linkResolver";
import { Paragraph } from "../Paragraph";
import clsx from "clsx";

type FeaturesListProps = {
  title: any;
  items: any[];
  sliceTheme?: string;
};

export const FeaturesList: FC<FeaturesListProps> = ({
  title,
  items,
  sliceTheme,
}) => (
  <div
    className={clsx(
      "text-white bg-primary px-4 md:px-16 pt-8 pb-12 sm:py-24",
      sliceTheme
    )}
  >
    <PrismicRichText
      field={title.richText}
      components={{
        heading2: ({ children }) => (
          <Heading2 className="sm:text-center">{children}</Heading2>
        ),
        strong: ({ children }) => (
          <strong className="text-primary font-normal">{children}</strong>
        ),
      }}
    />
    <div className="sm:grid sm:grid-cols-2 lg:grid-cols-4 gap-8 sm:pt-6 max-w-screen-xl2 mx-auto">
      {items.map(item => (
        <div key={uid(16)} className="py-4">
          {item.item_icon_svg && (
            <div
              className="w-24 h-24 -ml-6 sm:ml-0 sm:w-36 sm:h-36 sm:mb-4"
              dangerouslySetInnerHTML={{
                __html: item.item_icon_svg.text,
              }}
            />
          )}
          <div className="sm:pl-8">
            {item.item_title && (
              <PrismicRichText
                field={item.item_title.richText}
                components={{
                  heading3: ({ children }) => (
                    <h3 className="text-2xl font-display">{children}</h3>
                  ),
                }}
              />
            )}
            {item.item_body && (
              <PrismicRichText
                field={item.item_body.richText}
                linkResolver={linkResolver}
                components={{
                  paragraph: Paragraph,
                }}
              />
            )}
          </div>
        </div>
      ))}
    </div>
  </div>
);
