import React from "react";
import { Heading2 } from "../Heading2";
import { linkResolver } from "../../utils/linkResolver";
import { PrismicRichText } from "@prismicio/react";
import { Video } from "../Video";
import { Calendly } from "../Calendly";
import Section from "../Section";
import clsx from "clsx";

export enum SliceLayout {
  ONE_COLUMN = "One Column",
  TWO_COLUMNS = "Two Columns",
}

export const CalendlySection = ({ data: { primary } }) => {
  const {
    section_title,
    subline,
    main_content,
    slice_theme,
    slice_layout,
    calendly_url,
  } = primary;

  return (
    <Section className={slice_theme} paddingY="py-8 md:py-20 lg:py-24">
      <div
        className={clsx(
          "block",
          slice_layout === SliceLayout.TWO_COLUMNS
            ? "md:flex md:gap-8"
            : "md:text-center"
        )}
      >
        <div
          className={
            slice_layout === SliceLayout.TWO_COLUMNS
              ? "md:flex-1 md:max-w-1/2 md:w-1/2"
              : ""
          }
        >
          <PrismicRichText
            field={section_title.richText}
            linkResolver={linkResolver}
            components={{
              heading2: ({ children }) => <Heading2>{children}</Heading2>,
            }}
          />
          <p className="text-xl md:text-2xl font-display my-5">{subline}</p>
          <PrismicRichText
            field={main_content.richText}
            linkResolver={linkResolver}
            components={{
              embed: ({ node }) =>
                node.oembed.type === "video" ? (
                  <Video videoLink={node.oembed.embed_url} />
                ) : null,
              hyperlink: ({ children, node }) => (
                <a
                  className="text-secondary"
                  href={linkResolver(node.data)}
                  target={
                    node.data.link_type === "Document" ? "_self" : "_blank"
                  }
                >
                  {children}
                </a>
              ),
            }}
          />
        </div>
        <div
          className={clsx(
            "mt-12",
            slice_layout === SliceLayout.TWO_COLUMNS
              ? "md:flex-1 md:max-w-1/2 md:w-1/2 md:mt-0"
              : null
          )}
        >
          <Calendly calendlyLink={calendly_url} />
        </div>
      </div>
    </Section>
  );
};
