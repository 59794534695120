import * as React from "react";

function Twitter({
  style = {},
  width = "1.75rem",
  height = "1.75rem",
  className = "",
  viewBox = "0 0 3619.61 3619.61",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`svg-icon fill-current transition duration-300 ease-in-out ${
        className || ""
      }`}
    >
      <g clipPath="url(#clip0_1231_87)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.3745 0H2.6254C1.18113 0 0 1.1812 0 2.6254V20.3745C0 21.8187 1.1812 22.9999 2.6254 22.9999H20.3745C21.8187 22.9999 22.9999 21.8187 22.9999 20.3745V2.6254C22.9999 1.18113 21.8187 0 20.3745 0V0Z"
          fill="currentColor"
        />
        <path
          d="M20.1256 6.29432C19.4909 6.56823 18.8088 6.75328 18.0929 6.83653C18.8236 6.41038 19.3848 5.73557 19.649 4.93145C18.9651 5.32613 18.2078 5.61263 17.4015 5.76704C16.756 5.09778 15.8362 4.67969 14.8183 4.67969C12.8638 4.67969 11.279 6.22139 11.279 8.12302C11.279 8.39291 11.3104 8.65575 11.3707 8.90777C8.42933 8.76418 5.82155 7.39325 4.07596 5.30993C3.77131 5.81849 3.59674 6.40996 3.59674 7.04104C3.59674 8.23573 4.22155 9.28969 5.1712 9.90717C4.59106 9.8893 4.04534 9.73438 3.56819 9.47649C3.56778 9.49084 3.56778 9.50528 3.56778 9.5198C3.56778 11.1881 4.78775 12.5798 6.40681 12.8963C6.10984 12.975 5.79717 13.0171 5.47441 13.0171C5.24636 13.0171 5.02469 12.9954 4.80854 12.9553C5.25895 14.3233 6.56594 15.3189 8.1147 15.3466C6.90345 16.2702 5.37746 16.8207 3.71925 16.8207C3.43357 16.8207 3.15187 16.8043 2.875 16.7725C4.44127 17.7496 6.30158 18.3197 8.30023 18.3197C14.81 18.3197 18.3699 13.0727 18.3699 8.52223C18.3699 8.37294 18.3665 8.22448 18.3597 8.0767C19.0511 7.5913 19.6511 6.98489 20.1255 6.29432H20.1256Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1231_87">
          <rect width="23" height="23" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Twitter;
