import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { FeaturesList } from "./FeaturesList";

export const OurValues = () => {
  const data = useStaticQuery(ourValuesQuery);
  const { title, items } = data.allPrismicNewValues?.edges[0].node.data;

  return <FeaturesList title={title} items={items} />;
};

const ourValuesQuery = graphql`
  query NewValues {
    allPrismicNewValues {
      edges {
        node {
          data {
            title {
              richText
            }
            items {
              item_title {
                richText
              }
              item_body {
                richText
              }
              item_icon_svg {
                text
              }
            }
          }
        }
      }
    }
  }
`;
