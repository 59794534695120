import React, { FC } from "react";
import Section from "../Section";
import SectionText from "../SectionText";
import BeforeAfter from "../BeforeAfter";
import { SliceProps } from "../../types";

const BeforeAfterSection: FC<SliceProps> = ({ data: { primary } }) => {
  const { beforeafter_title, beforeafter_body } = primary;

  return (
    <Section paddingY="py-0">
      <div className="pt-12 xl:pt-32">
        <div className="flex flex-col space-y-10 md:space-y-20">
          <div>
            <SectionText
              label="SOLUTION"
              headline={beforeafter_title.text}
              text={beforeafter_body.text}
            />
          </div>
          <div>
            <BeforeAfter primary={primary} />
          </div>
        </div>
      </div>
    </Section>
  );
};

export default BeforeAfterSection;
