import clsx from "clsx";
import React, { useReducer, useState } from "react";

interface Window {
  dataLayer: any;
}

enum FieldType {
  PLAIN_TEXT = "Plain Text",
  NUMBER = "Number",
  EMAIL = "Email",
  PHONE_NUMBER = "Phone Number",
  TEXT_AREA = "Text Area",
}

const fieldTypes: Record<FieldType, string> = {
  [FieldType.PLAIN_TEXT]: "text",
  [FieldType.TEXT_AREA]: "textarea",
  [FieldType.NUMBER]: "number",
  [FieldType.PHONE_NUMBER]: "tel",
  [FieldType.EMAIL]: "email",
};

/**
 * INPUT COMPONENT
 */
const inputCls =
  "w-full rounded-default outline-none transition duration-300 ease-in-out border-[1px] border-gray-400 placeholder:text-gray-300 focus:border-gray-500 py-[11px] px-3.5 leading-4";

const Input = allProps => {
  const { type, className, required, ...props } = allProps;
  const [touched, setTouched] = useState(false);

  const onFocusOut = () => {
    setTouched(true);
  };

  return (
    <>
      {type === FieldType.TEXT_AREA ? (
        <textarea
          {...props}
          required
          className={clsx(
            inputCls,
            "block h-24",
            touched && "invalid:border-themeRed-300",
            className
          )}
          onBlur={touched ? null : onFocusOut}
        />
      ) : (
        <input
          type={fieldTypes[type]}
          {...props}
          required
          className={clsx(
            inputCls,
            touched && "invalid:border-themeRed-300",
            className
          )}
          onBlur={touched ? null : onFocusOut}
        />
      )}
    </>
  );
};
/**
 * END OF INPUT COMPONENT
 */

/**
 * TYPES
 */
type Field = {
  name: string;
  type: string;
  placeholder?: string;
  required?: boolean;
};

type Form = {
  name: string;
  submitLabel: string;
  fields: Field[];
};

enum FormStatus {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
}
/**
 * END OF TYPES
 */

/**
 * UTILS
 */
const encode = data => {
  const formData = new FormData();

  Object.keys(data).forEach(k => {
    formData.append(k, data[k]);
  });

  console.log(formData.get("full-name"));

  return formData;
};

const slugify = (str: string) => {
  return str
    .toLowerCase() // Convert to lowercase
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/[^\w\-]+/g, "") // Remove any non-word characters except hyphens
    .replace(/\-\-+/g, "-") // Replace multiple hyphens with a single hyphen
    .replace(/^-+/, "") // Remove leading hyphens
    .replace(/-+$/, ""); // Remove trailing hyphens
};
/**
 * END OF UTILS
 */

/**
 * REDUCER
 */
function reducer(state, action) {
  const result = { ...state };

  result[action.id] = action.value;

  return result;
}
/**
 * END OF REDUCER
 */

export const Form = ({ name, submitLabel, fields }) => {
  const [status, setStatus] = useState<FormStatus>();
  const [formData, dispatch] = useReducer(reducer, {
    "form-name": slugify(name),
  });

  const handleSubmit = event => {
    console.log(encode(formData));
    console.log(formData);
    fetch("/", {
      method: "POST",
      body: encode({ ...formData }),
    })
      .then(() => setStatus(FormStatus.SUCCESS))
      .catch(() => setStatus(FormStatus.ERROR));

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "FormSubmit",
      eventLabel: formData["form-name"],
    });

    event.preventDefault();
  };

  const handleChange = event => {
    const { id, value } = event.target;

    dispatch({
      id,
      value,
    });
  };

  return (
    <div>
      {status !== FormStatus.SUCCESS ? (
        <form
          name={formData["form-name"]}
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
          action="/thank-you/"
          className="xl:max-w-xl t-blue--red"
        >
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value={formData["form-name"]} />

          <section>
            <div className="grid grid-cols-1 gap-y-4 text-sm">
              {fields.map((field: Field) => {
                const slug = slugify(field.name);
                return (
                  <Input
                    key={slug}
                    type={field.type}
                    name={slugify(field.name)}
                    placeholder={field.placeholder || ""}
                    value={formData[name]}
                    onChange={handleChange}
                    id={slug}
                    required={field.required}
                  />
                );
              })}
            </div>
            <div className="py-6">
              <button
                className="inline-block py-3 xs:py-3.5 px-8 leading-4 xs:leading-6 md:leading-7 text-white text-sm xs:text-base md:text-lg font-semibold rounded-default cursor-pointer duration-300 ease-in-out bg-secondary hover:bg-secondary-hover tracking-wide"
                type="submit"
              >
                {submitLabel}
              </button>
            </div>
          </section>

          {status === FormStatus.ERROR && (
            <section>
              <div>
                <p className="text-red-600 text-sm">
                  Something went wrong, please email us at{" "}
                  <a href="mailto:sales@thisisstoked.com">
                    sales@thisisstoked.com
                  </a>
                  .
                </p>
              </div>
            </section>
          )}
        </form>
      ) : (
        <section className="py-20 md:py-0">
          <h2 className="text-h2 mb-6">
            Thanks for <br />
            getting in touch!{" "}
          </h2>
          <p className="mb-5">
            We appreciate you contacting us. One of our colleagues will get back
            in touch with you soon!
          </p>
          <p>Have a great day!</p>
        </section>
      )}
    </div>
  );
};
