import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { FeaturesList } from "./FeaturesList";

export const WhyChooseUs = ({ data: { primary } }) => {
  const data = useStaticQuery(whyChooseUsQuery);
  const { title, items } = data.allPrismicWhyUs?.edges[0].node.data;
  const slice_theme = primary?.slice_theme;

  return <FeaturesList title={title} items={items} sliceTheme={slice_theme} />;
};

const whyChooseUsQuery = graphql`
  query WhyChooseUs {
    allPrismicWhyUs {
      edges {
        node {
          data {
            title {
              richText
            }
            items {
              item_title {
                richText
              }
              item_body {
                richText
              }
              item_icon_svg {
                text
              }
            }
          }
        }
      }
    }
  }
`;
