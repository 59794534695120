import React, { FC } from "react";
import { PageListGallery } from "./PageListGallery";
import PageListSwiper from "./PageListSwiper";
import { SliceProps } from "../../types";

export const PageList: FC<SliceProps> = ({ data }) => {
  switch (data?.primary?.page_list_type) {
    case "gallery":
    case "cover":
      return <PageListGallery data={data} />;
    default:
      return <PageListSwiper data={data} />;
  }
};
